import { environment } from '../../constants/environment';
import { TicketResponse } from '../api';
import { HttpClient } from '../httpClient';
import { PaginatedResponse } from '../paginated.interface';

const ticketEndpoint = `${environment.endpoint}/ticket`;

export function loadTickets(httpClient: HttpClient) {
  return httpClient.get<PaginatedResponse<TicketResponse>>(`${ticketEndpoint}/me`);
}
