import { Button, Center, Modal, Stack, Text, useToast } from 'native-base';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ClaimResponse } from '../../../api/api';
import ClaimActive from '../../../components/Claim/ClaimActive';
import DealDetail from '../../../components/Deal/DealDetail';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { claimActivateThunk, claimConnectMediaThunk } from '../../../store/slices/claim.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

const CLAIM_PROCESS_TIME = 15 * 60000; // 15 min

export default function ClaimDetailScreen({ navigation, route }: RootStackScreenProps<'ClaimDetail'>) {
  const claim = useAppSelector((state) => state.claim.detail);
  const selectedMedia = useAppSelector((state) => state.claim.selectedMediaId);
  const deal = claim?.deal;
  const isActivated = claim?.activatedAt === null;
  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const toast = useToast();

  const activateClaim = useCallback((deal: ClaimResponse) => {
    dispatch(claimActivateThunk(deal.id));
    setModalVisible(false);
  }, []);

  useEffect(() => {
    navigation.setOptions({ title: deal?.title });
    if (claim && Date.now() - claim.activatedAt < CLAIM_PROCESS_TIME) {
      setShowActive(true);
    } else {
      setShowActive(false);
    }
  }, [claim]);

  useEffect(() => {
    if (claim && selectedMedia) {
      const claimId = claim.id;
      dispatch(claimConnectMediaThunk({ claimId, mediaId: selectedMedia })).then((response) => {
        if (response.meta.requestStatus === 'rejected') {
          toast.show({
            id: response.meta.requestId,
            title: (response as any).error.message,
            placement: 'bottom-right',
            mx: 10,
          });
        }
      });
    }
  }, [selectedMedia]);

  const openSpot = (spotId: string) => {
    navigation.navigate('SpotDetail', { spotId: spotId });
  };

  return deal ? (
    <>
      <Modal
        isOpen={modalVisible}
        closeOnOverlayClick={false}
        onClose={() => setModalVisible(false)}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}>
        <Modal.Content>
          <Modal.Header>Einlösen</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Text>
              Nach Einlösung ist der Gutschein 15 Minuten für Dich aktiv. Löse den Deal vor Ort ein und zeige ihn
              unserem Partner vor.
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onPress={() => {
                activateClaim(claim);
              }}>
              Einlösen
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {showActive ? (
        <ClaimActive claim={claim} onSpotClick={openSpot} onFinished={() => setShowActive(false)}></ClaimActive>
      ) : (
        <DealDetail deal={deal!} onSpotClick={openSpot}>
          {claim.connectedContent === null ? (
            <Stack p={4} space={4}>
              <Center>
                {isActivated ? (
                  <Button onPress={() => setModalVisible(true)}>Einlösen</Button>
                ) : (
                  <Button onPress={() => navigation.navigate('SelectPost')}>Deal verbinden</Button>
                )}
              </Center>
            </Stack>
          ) : null}
        </DealDetail>
      )}
    </>
  ) : null;
}
