import { Center, Heading, HStack, Pressable, Stack, Text } from 'native-base';
import { SpotlikedLogo } from '../../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../../components/ShadowBox';
import { useInfluencer } from '../../../hooks/useInfluencer';

import { useAppDispatch } from '../../../store/app.hooks';
import { logoutThunk } from '../../../store/slices/user.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function InfluencerVerificationScreen({
  navigation,
  route,
}: RootStackScreenProps<'InfluencerVerification'>) {
  const influencer = useInfluencer();
  const dispatch = useAppDispatch();

  async function logout() {
    await dispatch(logoutThunk());
    navigation.navigate('Login');
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} py={8} alignItems={'center'}>
      <SpotlikedLogo />
      <Stack space={2}>
        <ShadowBox>
          <Stack alignItems={'center'} space={4}>
            <Heading textAlign={'left'} size={'sm'}>
              Noch ein wenig Geduld...
            </Heading>
            <Text>
              Deine Anmeldung wird überprüft. Schon bald hast Du Zugriff auf die besten Deals der Hauptstadt. Wir
              schicken Dir in Kürze eine Bestätigungsmail.
            </Text>
          </Stack>
        </ShadowBox>

        <Center>
          <HStack p={3} space={3}>
            <Pressable onPress={() => logout()}>
              <Text textDecorationLine={'underline'}>Logout</Text>
            </Pressable>
          </HStack>
        </Center>
      </Stack>
    </Stack>
  );
}
