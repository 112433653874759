export interface Environment {
  production: boolean;
  endpoint: string;
  uploadPath: string;
}

const environmentLocal: Environment = {
  production: false,
  endpoint: 'https://localhost:3000',
  uploadPath: 'https://localhost:3000/uploads',
};

const environmentLocalMobile: Environment = {
  production: false,
  endpoint: 'http://192.168.178.48:3000',
  uploadPath: 'http://192.168.178.48:3000/uploads',
};

const environmentLocalDev: Environment = {
  production: false,
  endpoint: 'https://api.dev.spotliked.de',
  uploadPath: 'https://api.dev.spotliked.de/uploads',
};

const environmentProd: Environment = {
  production: true,
  endpoint: 'https://api.spotliked.de',
  uploadPath: 'https://api.spotliked.de/uploads',
};

export const environment = environmentProd;
