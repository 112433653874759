import { useFocusEffect } from '@react-navigation/native';
import { AspectRatio, Center, Image, ScrollView, Stack, Text } from 'native-base';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Pressable } from 'react-native';
import { TicketResponse } from '../../../../api/api';
import TicketSummery from '../../../../components/Ticket/TicketSummery';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { loadInboxThunk, resetUnreadedMessagesInboxThunk } from '../../../../store/slices/inbox.slice';
import { loadTicketThunk, setTicket } from '../../../../store/slices/ticket.slice';
import { InboxNavigationScreenProps } from './InboxNavigation';

export const EMPTY_IMAGE = require('./../../../../assets/images/empty-messages.png');

export default function InboxScreen({ navigation }: InboxNavigationScreenProps<'Inbox'>) {
  const apiResponse = useAppSelector((state) => state.ticket.api_response);
  const inboxApiResponse = useAppSelector((state) => state.inbox.api_response);
  const dispatch = useAppDispatch();

  const openTicket = useCallback((ticket: TicketResponse) => {
    dispatch(setTicket(ticket));
    navigation.getParent()!.navigate('TicketDetail', { ticketId: ticket.id });
  }, []);

  useEffect(() => {
    dispatch(loadTicketThunk());
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (inboxApiResponse?.unreadedMessages) {
        dispatch(resetUnreadedMessagesInboxThunk()).then(() => dispatch(loadInboxThunk()));
      }
    }, [inboxApiResponse]),
  );

  const hasTickets: boolean = useMemo(() => {
    if (apiResponse?.data) {
      apiResponse.data.length;
      return apiResponse.data.length > 0;
    }
    return false;
  }, [apiResponse]);

  return (
    <>
      {hasTickets ? (
        <ScrollView>
          <Center p={2}>
            <Stack w={'100%'} maxW={'900px'} space={2}>
              {apiResponse?.data?.map((ticket) => (
                <Pressable key={ticket.id} onPress={() => openTicket(ticket)}>
                  <TicketSummery ticket={ticket}></TicketSummery>
                </Pressable>
              ))}
            </Stack>
          </Center>
        </ScrollView>
      ) : (
        <Stack w={'100%'} h={'100%'} justifyContent={'center'} space={2} alignItems={'center'} p={4}>
          <AspectRatio ratio={1} w={'140px'} minW={'140px'}>
            <Image w={'100%'} h={'100%'} source={EMPTY_IMAGE}></Image>
          </AspectRatio>
          <Text>Es wurden noch keine Nachrichten für Dich hinterlegt</Text>
        </Stack>
      )}
    </>
  );
}
