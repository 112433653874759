import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/app.hooks';
import { loadAccessTokenFromLocalStorageThunk, UserState } from '../store/slices/user.slice';

const UserContext = createContext<UserState | null>(null);

export function UserProvider({ children }: { children: ReactNode }) {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    dispatch(loadAccessTokenFromLocalStorageThunk());
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser(): UserState | null {
  return useContext(UserContext);
}
