import Svg, { Path } from 'react-native-svg';

export function TabBarInboxDefault({ size = 24, color = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41421 4L12 12.5858L20.5858 4H3.41421ZM22 5.41421L15.4142 12L22 18.5858V5.41421ZM20.5858 20L14 13.4142L12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L10 13.4142L3.41421 20H20.5858ZM2 18.5858L8.58579 12L2 5.41421V18.5858ZM0.87868 2.87868C1.44129 2.31607 2.20435 2 3 2H21C21.7957 2 22.5587 2.31607 23.1213 2.87868C23.6839 3.44129 24 4.20435 24 5V19C24 19.7957 23.6839 20.5587 23.1213 21.1213C22.5587 21.6839 21.7957 22 21 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.316073 20.5587 0 19.7957 0 19V5C0 4.20435 0.316073 3.44129 0.87868 2.87868Z"
        fill={color}
      />
    </Svg>
  );
}

export function TabBarInboxActive({ size = 24, color = '#00AAF2', strokeColor = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H21C21.5304 3 22.0391 3.21071 22.4142 3.58579C22.7893 3.96086 23 4.46957 23 5V19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41421 4L12 12.5858L20.5858 4H3.41421ZM22 5.41421L15.4142 12L22 18.5858V5.41421ZM20.5858 20L14 13.4142L12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L10 13.4142L3.41421 20H20.5858ZM2 18.5858L8.58579 12L2 5.41421V18.5858ZM0.87868 2.87868C1.44129 2.31607 2.20435 2 3 2H21C21.7957 2 22.5587 2.31607 23.1213 2.87868C23.6839 3.44129 24 4.20435 24 5V19C24 19.7957 23.6839 20.5587 23.1213 21.1213C22.5587 21.6839 21.7957 22 21 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.316073 20.5587 0 19.7957 0 19V5C0 4.20435 0.316073 3.44129 0.87868 2.87868Z"
        fill={strokeColor}
      />
    </Svg>
  );
}
