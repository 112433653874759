import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { MainTabScreenProps } from '../VerifiedNavigation';
import InboxScreen from './InboxScreen';

export type InboxStackParamList = {
  Inbox: undefined;
};

export type InboxNavigationScreenProps<Screen extends keyof InboxStackParamList> = NativeStackScreenProps<
  InboxStackParamList,
  Screen
>;

const Stack = createNativeStackNavigator<InboxStackParamList>();

export default function InboxNavigator({ navigation }: MainTabScreenProps<'inbox'>) {
  return (
    <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen
        name="Inbox"
        component={InboxScreen}
        options={{
          title: 'Nachrichten',
        }}
      />
    </Stack.Navigator>
  );
}
