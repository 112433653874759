import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import { environment } from '../../constants/environment';
import { PostResponseDto, SignInRequestDto, SignUpRequestDto, TokenResponse, TokenResponseDto } from '../api';
import { HttpClient } from '../httpClient';

const authEndpoint = `${environment.endpoint}/auth`;

export async function loadAccessTokenFromLocalStore() {
  const accessToken = (await AsyncStorage.getItem('access_token')) ?? null;
  if (accessToken) {
    const user: { exp: number } = jwtDecode(accessToken);
    if (user && !isExpired(user)) {
      return accessToken;
    } else {
      AsyncStorage.removeItem('access_token');
      return null;
    }
  }
  return null;
}

export function isExpired(token: { exp: number }) {
  return Date.now() >= token.exp * 1000;
}

export function login(httpClient: HttpClient, signInRequestDto: SignInRequestDto) {
  return httpClient
    .post<TokenResponse>(`${authEndpoint}/sign-in`, signInRequestDto)
    .then((response) => response.access_token);
}

export function signUp(httpClient: HttpClient, signUpRequestDto: SignUpRequestDto) {
  return httpClient.post<PostResponseDto>(`${authEndpoint}/sign-up`, signUpRequestDto);
}

export function activate(httpClient: HttpClient, id: string) {
  return httpClient.get<PostResponseDto>(`${authEndpoint}/activate/${id}`);
}

export function refreshToken(httpClient: HttpClient) {
  return httpClient.get<TokenResponseDto>(`${authEndpoint}/refresh_token`);
}

export function resetPassword(httpClient: HttpClient, email: string) {
  return httpClient.post<PostResponseDto>(`${authEndpoint}/reset-password`, {
    email,
  });
}

export function refreshAccessToken(httpClient: HttpClient) {
  return httpClient.get<TokenResponseDto>(`${authEndpoint}/refresh_token`);
}

export function setNewPassword(httpClient: HttpClient, password: string, token: string) {
  return httpClient.post<PostResponseDto>(`${authEndpoint}/new-password`, {
    password,
    token,
  });
}
