import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ClaimResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { PaginatedResponse } from '../../api/paginated.interface';
import {
  claimActivate,
  claimConnectMedia,
  claimDeal,
  claimDealClaimable,
  claimLoadList,
} from '../../api/services/claim.service';
import { RootState } from '../app.store';

export const claimLoadListThunk = createAsyncThunk('claim/list', () => {
  return claimLoadList(httpClient);
});

export const claimDealThunk = createAsyncThunk('claim/deal', (dealId: string) => {
  return claimDeal(httpClient, dealId);
});

export const claimActivateThunk = createAsyncThunk('claim/activate', (claimId: string) => {
  return claimActivate(httpClient, claimId);
});

export const claimConnectMediaThunk = createAsyncThunk(
  'claim/connectMedia',
  ({ claimId, mediaId }: { claimId: string; mediaId: string }) => {
    return claimConnectMedia(httpClient, claimId, mediaId);
  },
);

export const claimDealClaimableThunk = createAsyncThunk('claim/deal-claimable', (dealId: string) => {
  return claimDealClaimable(httpClient, dealId);
});

const claimAdapter = createEntityAdapter<ClaimResponse>({
  selectId: (claim) => claim.id,
});

export interface ClaimState {
  detail: ClaimResponse | null;
  list: EntityState<ClaimResponse>;
  selectedMediaId: string | null;
  api_response: PaginatedResponse<ClaimResponse> | null;
}

const initialState: ClaimState = {
  detail: null,
  list: claimAdapter.getInitialState(),
  selectedMediaId: null,
  api_response: null,
};

export const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    setClaimDetail: (state, action: PayloadAction<ClaimResponse>) => {
      state.detail = action.payload;
    },
    setSelectedMedia: (state, action: PayloadAction<string>) => {
      state.selectedMediaId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(claimLoadListThunk.fulfilled, (state, action: PayloadAction<PaginatedResponse<ClaimResponse>>) => {
        claimAdapter.setAll(state.list, action.payload.data);
        state.api_response = action.payload;
      })
      .addCase(claimActivateThunk.fulfilled, (state, action: PayloadAction<ClaimResponse>) => {
        const claim = action.payload;
        state.detail = action.payload;
        claimAdapter.updateOne(state.list, { id: claim.id, changes: claim });
      })
      .addCase(claimConnectMediaThunk.fulfilled, (state, action: PayloadAction<ClaimResponse>) => {
        const claim = action.payload;
        state.selectedMediaId = null;
        claimAdapter.updateOne(state.list, { id: claim.id, changes: claim });
      })
      .addCase(claimConnectMediaThunk.rejected, (state) => {
        state.selectedMediaId = null;
      });
  },
});

export const { setClaimDetail, setSelectedMedia } = claimSlice.actions;
export const { selectAll: claimListAll } = claimAdapter.getSelectors((state: RootState) => state.claim.list);

export default claimSlice.reducer;
