import React, { useEffect } from 'react';
import SpotDetail from '../../../components/Spot/SpotDetail';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { spotLoadThunk } from '../../../store/slices/spot-detail.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function SpotDetailScreen({ navigation, route }: RootStackScreenProps<'SpotDetail'>) {
  const spot = useAppSelector((state) => state.spotDetail.api_response);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const spotId = route.params.spotId;
    dispatch(spotLoadThunk(spotId));
  }, []);

  useEffect(() => {
    navigation.setOptions({ title: spot?.title });
  }, [spot]);

  return spot ? <SpotDetail spot={spot!}></SpotDetail> : null;
}
