import { useInfluencer } from '../../hooks/useInfluencer';
import { NotVerfiedNavigator, NotVerfiedParamList } from './NotVerfied/NotVerifiedNavigator';
import { VerfiedNavigator, VerfiedParamList } from './Verfied/VerifiedNavigation';

export type LoggedInParamList = VerfiedParamList & NotVerfiedParamList;

export function LoggedInNavigator() {
  const influencer = useInfluencer();
  const isVerfied = Boolean(influencer?.verifiedAt);
  return isVerfied ? <VerfiedNavigator /> : <NotVerfiedNavigator />;
}
