import React, { useEffect } from 'react';
import TicketDetail from '../../../components/Ticket/TicketDetail';
import { useAppSelector } from '../../../store/app.hooks';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function TicketDetailScreen({ navigation, route }: RootStackScreenProps<'TicketDetail'>) {
  const ticket = useAppSelector((state) => state.ticket.selectedTicket);

  useEffect(() => {
    navigation.setOptions({ title: ticket?.subject });
  }, [ticket]);

  return ticket ? <TicketDetail ticket={ticket!}></TicketDetail> : null;
}
