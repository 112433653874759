import { environment } from '../../constants/environment';
import { InboxResponse } from '../api';
import { HttpClient } from '../httpClient';

const inboxEndpoint = `${environment.endpoint}/inbox`;

export function loadInbox(httpClient: HttpClient) {
  return httpClient.get<InboxResponse>(`${inboxEndpoint}/me`);
}

export function resetUnreadedMessages(httpClient: HttpClient) {
  return httpClient.post<InboxResponse>(`${inboxEndpoint}/me/reset-unreaded`, {});
}
