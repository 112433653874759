import { HStack, Stack, Text } from 'native-base';
import { useMemo } from 'react';
import { DealResponse } from '../../api/api';
import { DealCardSpotCaptionImage } from './DealCardSpotCaptionImage';

function DealSpotCaptionAddress({ address }: { address: DealResponse['spotCaption']['address'] }) {
  const addressAsString = useMemo(() => `${address.street}, ${address.areacode} ${address.place}`, [address]);
  return (
    <Text color={'city.600'} fontSize={10} isTruncated noOfLines={1}>
      {addressAsString}
    </Text>
  );
}

export default function DealSpotCaption({ spotCaption }: { spotCaption: DealResponse['spotCaption'] }) {
  return (
    <HStack alignItems={'center'} space={2} px={2} flex={1}>
      <DealCardSpotCaptionImage media={spotCaption.logo} />
      <Stack flex={1}>
        <Text fontSize={12} isTruncated noOfLines={1} fontWeight={'bold'}>
          {spotCaption.title}
        </Text>
        <DealSpotCaptionAddress address={spotCaption.address} />
      </Stack>
    </HStack>
  );
}
