import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InboxResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { loadInbox, resetUnreadedMessages } from '../../api/services/inbox.service';

export const loadInboxThunk = createAsyncThunk('inbox/me', () => {
  return loadInbox(httpClient);
});

export const resetUnreadedMessagesInboxThunk = createAsyncThunk('inbox/resetUnreadedMessages', () => {
  return resetUnreadedMessages(httpClient);
});

export interface InboxState {
  api_response: InboxResponse | null;
}

const initialState: InboxState = {
  api_response: null,
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadInboxThunk.fulfilled, (state, action: PayloadAction<InboxResponse>) => {
      state.api_response = action.payload;
    });
  },
});

export const {} = inboxSlice.actions;

export default inboxSlice.reducer;
