import { Center, Heading, HStack, Input, KeyboardAvoidingView, Pressable, Select, Stack, Text } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { Keyboard } from 'react-native';
import { InfluencerCreateRequestDto } from '../../../api/api';
import { SpotlikedLogo } from '../../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../../components/ShadowBox';
import { useInfluencer } from '../../../hooks/useInfluencer';

import { useAppDispatch } from '../../../store/app.hooks';
import { influencerSignUpThunk } from '../../../store/slices/influencer.slice';
import { logoutThunk, refreshAccessTokenThunk } from '../../../store/slices/user.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

type GenderType = InfluencerCreateRequestDto['contact']['gender'];

export default function InfluencerSignUpScreen({ navigation }: RootStackScreenProps<'InfluencerSignUp'>) {
  const influencer = useInfluencer();
  const [showLogo, setShowLogo] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState<GenderType>('none');
  const dispatch = useAppDispatch();

  const firstNameRef = useRef();
  const lastNameRef = useRef();

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', () => {
      setShowLogo(false);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setShowLogo(true);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    if (influencer) {
      navigation.navigate('InstagramLogin');
    }
  }, [influencer]);

  // TODO: need to be replaced in Native APP
  // this is a fix, because on IOS the Input element is loosing the focuse, because auf Navigations Stack
  const updateFirstName = (text: string) => {
    (firstNameRef.current as any).value = text;
  };

  // TODO: need to be replaced in Native APP
  // this is a fix, because on IOS the Input element is loosing the focuse, because auf Navigations Stack
  const updateLastName = (text: string) => {
    (lastNameRef.current as any).value = text;
  };

  async function signUp() {
    const firstName = (firstNameRef.current as any).value;
    const lastName = (lastNameRef.current as any).value;
    await dispatch(influencerSignUpThunk({ contact: { firstName, lastName, gender } }));
    await dispatch(refreshAccessTokenThunk());
    navigation.navigate('InstagramLogin');
  }

  async function logout() {
    await dispatch(logoutThunk());
    navigation.navigate('Login');
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} py={8} alignItems={'center'}>
      <Stack>{showLogo ? <SpotlikedLogo /> : null}</Stack>
      <KeyboardAvoidingView
        behavior="position"
        onResponderStart={() => setShowLogo(false)}
        onResponderEnd={() => setShowLogo(true)}>
        <Stack space={2}>
          <ShadowBox buttonText={'Speichern'} onButtonPress={() => signUp()}>
            <Heading textAlign={'left'} size={'sm'}>
              Bitte Daten eintragen
            </Heading>
            <Stack space={1}>
              <Text>Vorname</Text>
              <Input
                ref={firstNameRef}
                type={'text'}
                placeholder={'Vorname'}
                onChangeText={(text) => updateFirstName(text)}></Input>
            </Stack>
            <Stack space={1}>
              <Text>Nachname</Text>
              <Input
                ref={lastNameRef}
                type={'text'}
                placeholder={'Nachname'}
                onChangeText={(text) => updateLastName(text)}></Input>
            </Stack>
            <Stack space={1}>
              <Text>Anrede</Text>
              <Select
                placeholder={'Anrede'}
                defaultValue={'none'}
                onValueChange={(text) => setGender(text as GenderType)}>
                <Select.Item label={'Diverse'} value={'none'}></Select.Item>
                <Select.Item label={'Herr'} value={'male'}></Select.Item>
                <Select.Item label={'Frau'} value={'female'}></Select.Item>
              </Select>
            </Stack>
          </ShadowBox>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => logout()}>
                <Text textDecorationLine={'underline'}>Logout</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
    </Stack>
  );
}
