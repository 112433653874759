import { AspectRatio, Box, Heading, Image, ScrollView, Stack, Text } from 'native-base';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { ClaimResponse } from '../../../../api/api';

import DealCard from '../../../../components/Deal/DealCard';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { claimListAll, claimLoadListThunk, setClaimDetail } from '../../../../store/slices/claim.slice';
import { ClaimNavigationScreenProps } from './ClaimNavigation';

export const EMPTY_IMAGE = require('./../../../../assets/images/empty-deals.png');

function ClaimBoxPhaseOne({ children }: { children?: ReactNode }) {
  return (
    <Box p={3} borderWidth={1} borderColor={'city.200'} borderStyle={'solid'} borderRadius={'2xl'}>
      {children}
    </Box>
  );
}

function ClaimBoxPhaseTwo({ children }: { children?: ReactNode }) {
  return (
    <Box p={3} borderWidth={1} borderColor={'prawn.200'} borderStyle={'solid'} borderRadius={'2xl'}>
      {children}
    </Box>
  );
}

function ClaimBoxPhaseThree({ children }: { children?: ReactNode }) {
  return (
    <Box p={3} borderWidth={1} borderColor={'soda.600'} borderStyle={'solid'} borderRadius={'2xl'}>
      {children}
    </Box>
  );
}

function ClaimTypeSelector({ claim, children }: { claim: ClaimResponse; children?: ReactNode }) {
  const isActivated = claim.activatedAt !== null;
  const isConnected = claim.connectedContent !== null;

  const phase: number = isConnected ? 3 : isActivated ? 2 : 1;
  const box = useMemo(() => {
    switch (phase) {
      case 3:
        return <ClaimBoxPhaseThree>{children}</ClaimBoxPhaseThree>;
      case 2:
        return <ClaimBoxPhaseTwo>{children}</ClaimBoxPhaseTwo>;
      default:
        return <ClaimBoxPhaseOne>{children}</ClaimBoxPhaseOne>;
    }
  }, [phase]);

  return box;
}

export default function ClaimScreen({ navigation }: ClaimNavigationScreenProps<'Claims'>) {
  const claimList = useAppSelector(claimListAll);
  const dispatch = useAppDispatch();

  const openClaimDetail = useCallback((claim: ClaimResponse) => {
    dispatch(setClaimDetail(claim));
    navigation.getParent()!.navigate('ClaimDetail', { claimId: claim.id });
  }, []);

  useEffect(() => {
    dispatch(claimLoadListThunk());
  }, []);

  const reservedClaimList = useMemo(() => claimList.filter((claim) => !claim.activatedAt), [claimList]);
  const notConnectedClaimList = useMemo(
    () => claimList.filter((claim) => claim.activatedAt && !claim.connectedContent),
    [claimList],
  );
  const hasClaims = useMemo(() => claimList.length > 0, [claimList]);

  return (
    <>
      {hasClaims ? (
        <ScrollView>
          <Stack space={4} p={4} alignItems={'center'}>
            {reservedClaimList.length ? (
              <Stack space={2}>
                <Heading>Reservierte Deals</Heading>
                <Stack w={'100%'} alignItems={'center'} p={2} space={4}>
                  {reservedClaimList.map((claim) => (
                    <ClaimTypeSelector key={claim.id} claim={claim}>
                      <DealCard deal={claim.deal} showDescription={false} onClick={() => openClaimDetail(claim)} />
                    </ClaimTypeSelector>
                  ))}
                </Stack>
              </Stack>
            ) : null}
            {notConnectedClaimList.length ? (
              <Stack space={2}>
                <Heading>Deals verbinden</Heading>
                <Stack w={'100%'} alignItems={'center'} p={2} space={4}>
                  {notConnectedClaimList.map((claim) => (
                    <ClaimTypeSelector key={claim.id} claim={claim}>
                      <DealCard deal={claim.deal} showDescription={false} onClick={() => openClaimDetail(claim)} />
                    </ClaimTypeSelector>
                  ))}
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </ScrollView>
      ) : (
        <Stack w={'100%'} h={'100%'} justifyContent={'center'} space={2} alignItems={'center'} p={4}>
          <AspectRatio ratio={1} w={'140px'} minW={'140px'}>
            <Image w={'100%'} h={'100%'} source={EMPTY_IMAGE}></Image>
          </AspectRatio>
          <Text>Du hast noch keine Deals Reserviert</Text>
        </Stack>
      )}
    </>
  );
}
