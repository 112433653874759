import { combineReducers, configureStore, PayloadAction } from '@reduxjs/toolkit';
import ClaimReducer from './slices/claim.slice';
import DealDetailReducer from './slices/deal-detail.slice';
import FavoriteReducer from './slices/favorite.slice';
import InboxReducer from './slices/inbox.slice';
import InfluencerReducer from './slices/influencer.slice';
import MainPageReducer from './slices/main-page.slice';
import SearchReducer from './slices/search.slice';
import SpotDetailReducer from './slices/spot-detail.slice';
import TicektReducer from './slices/ticket.slice';
import UserReducer from './slices/user.slice';

const reducer = combineReducers({
  user: UserReducer,
  influencer: InfluencerReducer,
  search: SearchReducer,
  claim: ClaimReducer,
  favorite: FavoriteReducer,
  inbox: InboxReducer,
  ticket: TicektReducer,
  dealDetail: DealDetailReducer,
  spotDetail: SpotDetailReducer,
  main: MainPageReducer,
});

const rootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
