import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useToken } from 'native-base';
import React from 'react';
import { TabBarIcon } from '../../../../components/Icons/TabBarIcon/TabBarIcon';
import { useAppSelector } from '../../../../store/app.hooks';
import { MainTabParamList } from '../VerifiedNavigation';
import ClaimNavigator from './ClaimNavigation';
import HomeNavigator from './HomeNavigation';
import InboxNavigator from './InboxNavigation';
import ProfileScreen from './ProfileScreen';

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const MainTab = createBottomTabNavigator<MainTabParamList>();

const defaultInboxOption = {
  title: 'Inbox',
  tabBarIcon: ({ color }: { color: string }) => <TabBarIcon name="inbox" color={color} />,
  headerShown: false,
};

export function MainTabNavigator() {
  const [sodaColor] = useToken('colors', ['soda.600']);
  const [inboxOption, setInboxOption] = React.useState<any>(defaultInboxOption);

  const unreadedMessages = useAppSelector((state) => state.inbox.api_response?.unreadedMessages);

  React.useEffect(() => {
    if (!unreadedMessages) {
      setInboxOption(defaultInboxOption);
    } else {
      setInboxOption({
        title: 'Inbox',
        tabBarIcon: ({ color }: { color: string }) => <TabBarIcon name="inbox" color={color} />,
        headerShown: false,
        tabBarBadge: '',
        tabBarBadgeStyle: {
          minWidth: 12,
          minHeight: 12,
          maxWidth: 12,
          maxHeight: 12,
          backgroundColor: sodaColor,
        },
      });
    }
  }, [unreadedMessages]);

  return (
    <MainTab.Navigator
      id="main"
      initialRouteName="home"
      screenOptions={{
        tabBarLabelPosition: 'below-icon',
        tabBarActiveTintColor: sodaColor,
        tabBarItemStyle: {
          paddingBottom: 4,
          flexDirection: 'column',
          flexGrow: 1,
        },
      }}>
      {/* <MainTab.Screen
        name="spots"
        component={SpotNavigator}
        options={{
          title: 'Spots',
          tabBarIcon: ({ color }) => <TabBarIcon name="spots" color={color} />,
          headerShown: false,
        }}
      /> */}
      <MainTab.Screen
        name="home"
        component={HomeNavigator}
        options={{
          title: 'Spots',
          tabBarIcon: ({ color }) => <TabBarIcon name="spots" color={color} />,
          headerShown: false,
        }}
      />
      <MainTab.Screen
        name="claims"
        component={ClaimNavigator}
        options={{
          title: 'Deals',
          tabBarIcon: ({ color }) => <TabBarIcon name="deals" color={color} />,
          headerShown: false,
        }}
      />
      {/* <MainTab.Screen
        name="favorites"
        component={FavoriteNavigator}
        options={{
          title: 'Favorites',
          tabBarIcon: ({ color }) => <TabBarIcon name="favorites" color={color} />,
          headerShown: false,
        }}
      /> */}
      <MainTab.Screen name="inbox" component={InboxNavigator} options={inboxOption} />
      <MainTab.Screen
        name="profile"
        component={ProfileScreen}
        options={{
          title: 'Profil',
          tabBarIcon: ({ color }) => <TabBarIcon name="profile" color={color} />,
          tabBarBadgeStyle: {
            flex: 1,
          },
        }}
      />
    </MainTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
/* function TabBarIcon(props: { name: React.ComponentProps<typeof FontAwesome>['name']; color: string }) {
   return <FontAwesome size={20} {...props} />;
 } */
