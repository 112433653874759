export interface HttpClient {
  accessToken: string | null;
  get<T>(url: string, params?: object): Promise<T>;
  post<T>(url: string, data?: object): Promise<T>;
}

class DefaultHttpClient implements HttpClient {
  accessToken: string | null = null;

  constructor() {}

  get<T>(url: string, params?: object): Promise<T> {
    const headers = this.getHeaders();
    const config: RequestInit = {
      method: 'GET',
      headers,
    };
    let paramsAsString = '';
    if (params && Object.keys(params).length > 0) {
      paramsAsString += '?';
      for (let param of Object.keys(params)) {
        const key = encodeURI(param);
        const value: string = encodeURI((params as any)[param]);
        paramsAsString += `${key}=${value}&`;
      }
    }
    return fetch(`${url}${paramsAsString}`, config).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response.json().then((e) => {
        let message = e.message && e.message.length > 0 ? e.message : e.error;
        throw new Error(message);
      });
    });
  }

  post<T>(url: string, data?: object): Promise<T> {
    const headers = this.getHeaders();
    const config: RequestInit = {
      method: 'POST',
      headers,
    };
    if (data) {
      config.body = JSON.stringify(data);
    }
    return fetch(url, config).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response.json().then((e) => {
        let message = e.message && e.message.length > 0 ? e.message : e.error;
        throw new Error(message);
      });
    });
  }

  private getHeaders(): RequestInit['headers'] {
    let headers: RequestInit['headers'];
    if (this.accessToken) {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.accessToken}`,
      };
    } else {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }
    return headers;
  }
}

export const httpClient = new DefaultHttpClient();
