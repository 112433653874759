import { createContext, ReactNode, useContext, useEffect } from 'react';
import { InfluencerResponse } from '../api/api';
import { useAppDispatch, useAppSelector } from '../store/app.hooks';
import { influencerLoadThunk } from '../store/slices/influencer.slice';

const InfluencerContext = createContext<InfluencerResponse | null>(null);

export function InfluencerProvider({ children }: { children: ReactNode }) {
  const influencer = useAppSelector((state) => state.influencer.api_response);
  const dispatch = useAppDispatch();

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    dispatch(influencerLoadThunk());
  }, []);

  return <InfluencerContext.Provider value={influencer}>{children}</InfluencerContext.Provider>;
}

export function useInfluencer(): InfluencerResponse | null {
  return useContext(InfluencerContext);
}
