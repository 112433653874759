import { extendTheme } from 'native-base';

export const spotLikedTheme = extendTheme({
  fontConfig: {
    DMSans: {
      100: {
        normal: 'dm-sans',
      },
      200: {
        normal: 'dm-sans',
      },
      300: {
        normal: 'dm-sans',
      },
      400: {
        normal: 'dm-sans',
      },
      500: {
        normal: 'dm-sans',
      },
      600: {
        normal: 'dm-sans-medium',
      },
      700: {
        normal: 'dm-sans-medium',
      },
      800: {
        normal: 'dm-sans-bold',
      },
      900: {
        normal: 'dm-sans-bold',
      },
    },
  },
  fonts: {
    heading: 'DMSans',
    body: 'DMSans',
  },
  colors: {
    soda: {
      50: '#e7f8fe',
      100: '#e7f8fe',
      200: '#b6ebfb',
      300: '#86def8',
      400: '#56d1f6',
      500: '#25c4f3',
      600: '#0dbbee', // default
      700: '#0985a9',
      800: '#075f79',
      900: '#043949',
    },
    city: {
      50: '#f0f1f4',
      100: '#f0f1f4',
      200: '#d3d6de',
      300: '#b6bac8',
      400: '#999eb2',
      500: '#7c839c',
      600: '#858ba3', // default
      700: '#373a49',
      800: '#21232c',
      900: '#0b0c0f',
    },
    prawn: {
      50: '#ffe5e5',
      100: '#ffe5e5',
      200: '#ffb3b3',
      300: '#ff8080',
      400: '#ff4d4d',
      500: '#ff1a1a',
      600: '#ff4a4a', // default
      700: '#b30000',
      800: '#800000',
      900: '#4d0000',
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'soda',
      },
    },
    Input: {
      defaultProps: {
        colorScheme: 'soda',
      },
    },
    Spinner: {
      defaultProps: {
        colorScheme: 'soda',
      },
    },
  },
});
