import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DealResponse } from '../../api/api';

export interface DealDetailState {
  deal: DealResponse | null;
}

const initialState: DealDetailState = {
  deal: null,
};

export const dealDetailSlice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    setDeal: (state, action: PayloadAction<DealResponse | null>) => {
      state.deal = action.payload;
    },
  },
});

export const { setDeal } = dealDetailSlice.actions;

export default dealDetailSlice.reducer;
