import { FontAwesome } from '@expo/vector-icons';
import { AspectRatio, Box, HStack, Image, Text, useToken } from 'native-base';
import { useMemo } from 'react';
import { DealResponse } from '../../api/api';
import { getImageUrl, ImageSize } from '../utils';

function DealDetailTypeBadge({ type }: { type: DealResponse['type'] }) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  return (
    <HStack
      bg={'white'}
      position={'absolute'}
      top={'0'}
      px={'2'}
      py={'1'}
      mx={'2'}
      my={'2'}
      borderRadius={30}
      space={2}>
      <FontAwesome size={14} name={type === 'reel' ? 'video-camera' : 'image'} color={sodaColor} />
      <Text fontSize={10} px={'0'} ml={'-1'} color={'soda.600'}>
        {type === 'reel' ? 'Reel' : 'Post'}
      </Text>
    </HStack>
  );
}

export function DealDetailImage({ media, type }: { media: DealResponse['media']; type: DealResponse['type'] }) {
  const image = useMemo(() => getImageUrl(media.gallery[0], ImageSize.LG), [media]);

  return (
    <Box overflow={'hidden'}>
      <AspectRatio w={'100%'} ratio={1.25} minW={'100%'}>
        <Image
          source={{
            uri: image,
          }}
          alt="image of the Deal"
        />
      </AspectRatio>
      <DealDetailTypeBadge type={type} />
    </Box>
  );
}
