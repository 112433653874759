import { Button, Spinner, Stack, Text } from 'native-base';
import React, { ReactNode } from 'react';
import { hardShadow } from '../constants/shadow';
import SlideUpAnimation from './Animations/SlideUpAnimation';

export interface ShadowBoxProps {
  children?: ReactNode;
  buttonText?: string;
  showLoading?: boolean;
  onButtonPress?: () => void;
}

export default function ShadowBox({ children, buttonText, showLoading, onButtonPress }: ShadowBoxProps) {
  return (
    <SlideUpAnimation>
      <Stack
        backgroundColor={'white'} /* backgroundColor is needed for ios shadow  */
        w={350}
        maxWidth={'100%'}
        style={hardShadow}
        rounded={'xl'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Stack w={'100%'} p={'6'} py={'6'} space={6}>
          {children}
        </Stack>
        {buttonText ? (
          <Button
            w={'100%'}
            rounded={'xl'}
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            mt={4}
            onPress={onButtonPress}>
            {showLoading ? (
              <Spinner accessibilityLabel="login user" color={'soda.900'} h={'30px'} />
            ) : (
              <Text color={'white'} fontWeight={'bold'} fontSize={20}>
                {buttonText}
              </Text>
            )}
          </Button>
        ) : null}
      </Stack>
    </SlideUpAnimation>
  );
}
