import { FontAwesome } from '@expo/vector-icons';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { Pressable } from 'native-base';
import { Platform } from 'react-native';
import { MainTabScreenProps } from '../VerifiedNavigation';
import HomeScreen from './HomeScreen';

export type HomeStackParamList = {
  Home: undefined;
};

export type HomeNavigationScreenProps<Screen extends keyof HomeStackParamList> = NativeStackScreenProps<
  HomeStackParamList,
  Screen
>;

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

const Stack = createNativeStackNavigator<HomeStackParamList>();

export default function HomeNavigator({ navigation }: MainTabScreenProps<'home'>) {
  return (
    <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Spots',
          headerRight: () =>
            isMobile ? (
              <Pressable
                onPress={() => navigation.getParent()!.navigate('Map')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}>
                <FontAwesome name="map" size={20} style={{ marginRight: 15 }} />
              </Pressable>
            ) : null,
        }}
      />
    </Stack.Navigator>
  );
}
