import { AspectRatio, Box, Image } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { SpotResponse } from '../../api/api';
import { getImageUrl, ImageSize } from '../utils';

export function SpotImage({ media, imageOverlay }: { media: SpotResponse['media']; imageOverlay?: ReactNode }) {
  const image = useMemo(() => getImageUrl(media.gallery[0], ImageSize.LG), [media]);

  return (
    <Box w={'100%'} position={'relative'}>
      <AspectRatio w={'100%'} ratio={2} minW={'100%'}>
        <Image
          w={'100%'}
          source={{
            uri: image,
          }}
          alt="image of the Spot"
        />
      </AspectRatio>
      {imageOverlay}
    </Box>
  );
}
