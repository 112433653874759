import { Box, Center, Heading, HStack, Pressable, Spinner, Stack, Text } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { ClaimResponse } from '../../api/api';
import { hardShadow } from '../../constants/shadow';
import SlideUpAnimation from '../Animations/SlideUpAnimation';
import DealSpotCaption from '../Deal/DealSpotCaption';
import { countDownTimer, timestampToDate } from '../utils';
import { ClaimActiveImage } from './ClaimActiveImage';

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const CLAIM_PROCESS_TIME = 15 * 60000; // 15 min

export interface ClaimActiveProps {
  claim: ClaimResponse;
  onFinished?: () => void;
  onSpotClick?: (id: string) => void;
}

export default function ClaimActive({ claim, onFinished, onSpotClick }: ClaimActiveProps) {
  const deal = claim.deal;
  const date = useMemo(() => timestampToDate(claim.activatedAt), []);

  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const endTime = claim.activatedAt + CLAIM_PROCESS_TIME;
    const interval = setInterval(() => {
      const timer = countDownTimer(endTime);
      setCountdown(timer.min + ':' + timer.sec);
      if (timer.sec === '00' && timer.min == '00') {
        onFinished?.();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [claim]);

  const openSpot = () => {
    if (onSpotClick) {
      onSpotClick(deal.spotId);
    }
  };

  return (
    <Center w={'100%'} h={'100%'}>
      <SlideUpAnimation>
        <Box w={'320px'} rounded={'2xl'} backgroundColor={'white'} borderColor={'city.200'} style={hardShadow}>
          <ClaimActiveImage media={deal.media}>
            <Stack space={2} alignItems={'center'}>
              <Heading color={'white'}>Deal eingelöst</Heading>
              <Heading color={'white'} fontSize={20}>
                {date}
              </Heading>
              <Center position={'relative'}>
                <Spinner size={140} color={'soda.500'}></Spinner>
                <Center position={'absolute'} w={'100%'} h={'100%'}>
                  <Text color={'white'} fontSize={20}>
                    {countdown}
                  </Text>
                </Center>
              </Center>
            </Stack>
          </ClaimActiveImage>
          <Stack p={4} space={3}>
            <Heading size={'sm'} height={'10'} numberOfLines={2} isTruncated={true}>
              {deal.title}
            </Heading>
            <Text isTruncated numberOfLines={3} color={'city.600'} height={'16'}>
              {deal.description}
            </Text>
            <HStack alignItems={'stretch'} space={4} justifyContent={'space-between'}>
              <Stack>
                <HStack space={2}>
                  <Text textDecorationLine={'line-through'} color={'city.600'}>
                    {formatter.format(deal.regularPrice)}
                  </Text>
                  <Text color={'city.700'}>{deal.discountPercent * 100}%</Text>
                </HStack>
                <Text fontSize={'2xl'} color={'soda.600'}>
                  {formatter.format(deal.reducedPrice)}
                </Text>
              </Stack>
            </HStack>
          </Stack>
          {deal.spotCaption ? (
            <Pressable bgColor={'city.50'} borderBottomRadius={'2xl'} p={2} onPress={() => openSpot()}>
              <DealSpotCaption spotCaption={deal.spotCaption}></DealSpotCaption>
            </Pressable>
          ) : null}
        </Box>
      </SlideUpAnimation>
    </Center>
  );
}
