import { FontAwesome } from '@expo/vector-icons';
import { Text } from 'native-base';
import { useMemo } from 'react';
import { SpotResponse } from '../../api/api';

export default function SpotDetailAddress({ address }: { address: SpotResponse['address'] }) {
  const addressAsString = useMemo(() => `${address.street}, ${address.areacode} ${address.place}`, [address]);
  return (
    <Text color={'city.600'} w={'100%'} isTruncated noOfLines={2}>
      <FontAwesome name="location-arrow"></FontAwesome> {addressAsString}
    </Text>
  );
}
