import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfluencerCreateRequestDto, InfluencerResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { loadData, myInstagramMedia, sendAuthCode, signUp } from '../../api/services/influencer.service';

export const influencerLoadThunk = createAsyncThunk('influencer/load', () => {
  return loadData(httpClient);
});

export const influencerSignUpThunk = createAsyncThunk(
  'influencer/signUp',
  (createInfluencerDto: InfluencerCreateRequestDto) => {
    return signUp(httpClient, createInfluencerDto);
  },
);

export const influencerMyInstagramMediaThunk = createAsyncThunk('influencer/myInstagramMedia', () => {
  return myInstagramMedia(httpClient);
});

export const influencerSendAuthCodeThunk = createAsyncThunk('influencer/sendAuthCode', (code: string) => {
  return sendAuthCode(httpClient, code);
});

export interface InfluencerState {
  code: string | null;
  api_response: InfluencerResponse | null;
}

const initialState: InfluencerState = {
  code: null,
  api_response: null,
};

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string | null>) => {
      state.code = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(influencerLoadThunk.fulfilled, (state, action: PayloadAction<InfluencerResponse | null>) => {
        state.api_response = action.payload;
      })
      .addCase(influencerSignUpThunk.fulfilled, (state, action: PayloadAction<InfluencerResponse | null>) => {
        state.api_response = action.payload;
      })
      .addCase(influencerSendAuthCodeThunk.fulfilled, (state, action: PayloadAction<InfluencerResponse | null>) => {
        state.api_response = action.payload;
        state.code = null;
      });
  },
});

export const { setCode } = influencerSlice.actions;

export default influencerSlice.reducer;
