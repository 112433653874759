import { Box, Center, Heading, HStack, KeyboardAvoidingView, Pressable, Stack, Text, useToast } from 'native-base';
import { useRef, useState } from 'react';
import { PostResponseDto } from '../../api/api';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';
import { InputWithIcon, PasswordInput } from '../../components/StyledInput';

import { Animated } from 'react-native';
import LinkFooter from '../../components/Utils/LinkFooter';
import { useAppDispatch } from '../../store/app.hooks';
import { activateThunk, signUpThunk } from '../../store/slices/user.slice';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function SignUpScreen({ navigation }: RootStackScreenProps<'SignUp'>) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const toast = useToast();

  const shakeValue = useRef(new Animated.Value(0)).current;
  const shakeAnimation = useRef(
    Animated.sequence([
      Animated.timing(shakeValue, {
        toValue: -4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: -4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }),
    ]),
  );

  const dispatch = useAppDispatch();

  async function signUp() {
    if (!email || !password) {
      return;
    }
    dispatch(signUpThunk({ email: email.trim().toLocaleLowerCase(), password })).then((response) => {
      if (response.meta.requestStatus === 'rejected') {
        shakeAnimation.current.reset();
        shakeAnimation.current.start();
        toast.show({
          id: response.meta.requestId,
          title: (response as any).error.message,
          placement: 'bottom-right',
          mx: 10,
        });
      }
      if (response.meta.requestStatus === 'fulfilled') {
        const responseData = response.payload as PostResponseDto;
        const payload = responseData.payload as { id?: string };
        const id: string | undefined = payload?.id;
        if (id) {
          dispatch(activateThunk(id));
        }
        navigation.navigate('SignUpSuccess');
      }
      return response;
    });
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <Animated.View style={{ transform: [{ translateX: shakeValue }] }}>
            <ShadowBox buttonText="Registrieren" onButtonPress={() => signUp()}>
              <Heading textAlign={'left'} size={'sm'}>
                Neues Account erstellen
              </Heading>
              <InputWithIcon
                placeholder={'Email'}
                value={email}
                onChangeText={(text) => setEmail(text)}
                onSubmitEditing={() => signUp()}
                iconName={'user'}></InputWithIcon>
              <Box>
                <PasswordInput
                  placeholder={'Passwort'}
                  value={password}
                  onSubmitEditing={() => signUp()}
                  onChangeText={(text) => setPassword(text)}></PasswordInput>
                <Text fontSize={'xs'}>min. 6 Zeichen</Text>
              </Box>
            </ShadowBox>
          </Animated.View>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => navigation.navigate('Login')}>
                <Text textDecorationLine={'underline'}>Zurück zu Login</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
