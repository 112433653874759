import { MediaFileDto, MediaFileSizeListItem } from '../api/api';
import { environment } from '../constants/environment';

const uploadPath = environment.uploadPath;

export enum ImageSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export function getImageUrl(image: MediaFileDto, imageSize = ImageSize.SM) {
  if (!image || !image.id) {
    return;
  }
  const relatedId = image.relatedId;
  let size: MediaFileSizeListItem | null = null;
  switch (imageSize) {
    case ImageSize.LG: {
      size = image.sizeList[2];
      break;
    }
    case ImageSize.MD: {
      size = image.sizeList[1];
      break;
    }
    default: {
      size = image.sizeList[0];
      break;
    }
  }
  /* fallback if no image exists for the size */
  if (!size) {
    size = image.sizeList[0];
  }
  return `${uploadPath}/${relatedId}/${size.width}x${size.height}/${image.fileId}.jpg`;
}

export function timestampToDate(timestamp: number) {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString('de-DE', { dateStyle: 'short' })}, ${date.toLocaleTimeString('de-DE', {
    timeStyle: 'short',
  })} Uhr`;
}

const milliSecondsInASecond = 1000;
const minutesInAnHour = 60;
const SecondsInAMinute = 60;

export function countDownTimer(time: number) {
  const timeDifference = time - Date.now();
  let minInString = '00';
  let secInString = '00';
  if (timeDifference > 0) {
    let sec = Math.floor((timeDifference / milliSecondsInASecond) % SecondsInAMinute);
    let min = Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour)) % SecondsInAMinute);
    minInString = min.toString();
    secInString = sec.toString();
    if (min <= 9) {
      minInString = '0' + minInString;
    }
    if (sec <= 9) {
      secInString = '0' + secInString;
    }
  }
  return { min: minInString, sec: secInString };
}
