import Svg, { Path } from 'react-native-svg';

export function TabBarProfileDefault({ size = 24, color = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7657 14.4261L17.9839 16.2676C19.2302 16.9798 20 18.3054 20 19.741V20.486H18V19.741C18 19.0229 17.6153 18.3603 16.9921 18.0044L13.7723 16.1619L14.7657 14.4261Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.01606 16.2676L9.22947 14.4246L10.2245 16.1594L7.00794 18.0044C6.38466 18.3603 6 19.0229 6 19.741V20.486H4V19.741C4 18.3054 4.76983 16.9798 6.01606 16.2676Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C10.3433 7 9 8.34328 9 10V12C9 13.6567 10.3433 15 12 15C13.6567 15 15 13.6567 15 12V10C15 8.34328 13.6567 7 12 7ZM7 10C7 7.23872 9.23872 5 12 5C14.7613 5 17 7.23872 17 10V12C17 14.7613 14.7613 17 12 17C9.23872 17 7 14.7613 7 12V10Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.33975C13.906 1.55342 10.094 1.55342 7 3.33975C3.90599 5.12607 2 8.42734 2 12C2 15.5727 3.90599 18.8739 7 20.6603C10.094 22.4466 13.906 22.4466 17 20.6603C20.094 18.8739 22 15.5727 22 12C22 8.42734 20.094 5.12607 17 3.33975ZM6 1.6077C9.71281 -0.535898 14.2872 -0.535898 18 1.6077C21.7128 3.75129 24 7.71281 24 12C24 16.2872 21.7128 20.2487 18 22.3923C14.2872 24.5359 9.71281 24.5359 6 22.3923C2.28719 20.2487 0 16.2872 0 12C0 7.71281 2.28719 3.75129 6 1.6077Z"
        fill={color}
      />
    </Svg>
  );
}

export function TabBarProfileActive({ size = 24, color = '#00AAF2', strokeColor = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 15.9299 20.9034 19.5613 17.5 21.5263C14.0966 23.4912 9.90341 23.4912 6.5 21.5263C3.09659 19.5613 1 15.9299 1 12C1 8.07008 3.09659 4.43868 6.5 2.47372C9.90341 0.50876 14.0966 0.50876 17.5 2.47372C20.9034 4.43868 23 8.07008 23 12Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7657 14.4261L17.9839 16.2676C19.2302 16.9798 20 18.3054 20 19.741V20.486H18V19.741C18 19.0229 17.6153 18.3603 16.9921 18.0044L13.7723 16.1619L14.7657 14.4261Z"
        fill={strokeColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.01606 16.2676L9.22947 14.4246L10.2245 16.1594L7.00794 18.0044C6.38466 18.3603 6 19.0229 6 19.741V20.486H4V19.741C4 18.3054 4.76983 16.9798 6.01606 16.2676Z"
        fill={strokeColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C10.3433 7 9 8.34328 9 10V12C9 13.6567 10.3433 15 12 15C13.6567 15 15 13.6567 15 12V10C15 8.34328 13.6567 7 12 7ZM7 10C7 7.23872 9.23872 5 12 5C14.7613 5 17 7.23872 17 10V12C17 14.7613 14.7613 17 12 17C9.23872 17 7 14.7613 7 12V10Z"
        fill={strokeColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.33975C13.906 1.55342 10.094 1.55342 7 3.33975C3.90599 5.12607 2 8.42734 2 12C2 15.5727 3.90599 18.8739 7 20.6603C10.094 22.4466 13.906 22.4466 17 20.6603C20.094 18.8739 22 15.5727 22 12C22 8.42734 20.094 5.12607 17 3.33975ZM6 1.6077C9.71281 -0.535898 14.2872 -0.535898 18 1.6077C21.7128 3.75129 24 7.71281 24 12C24 16.2872 21.7128 20.2487 18 22.3923C14.2872 24.5359 9.71281 24.5359 6 22.3923C2.28719 20.2487 0 16.2872 0 12C0 7.71281 2.28719 3.75129 6 1.6077Z"
        fill={strokeColor}
      />
    </Svg>
  );
}
