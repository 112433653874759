import { useToken } from 'native-base';
import { TabBarDealsActive, TabBarDealsDefault } from './TabBarDeals';
import { TabBarFavoritesActive, TabBarFavoritesDefault } from './TabBarFavorites';
import { TabBarInboxActive, TabBarInboxDefault } from './TabBarInbox';
import { TabBarProfileActive, TabBarProfileDefault } from './TabBarProfile';
import { TabBarSpotsActive, TabBarSpotsDefault } from './TabBarSpots';

export function TabBarIcon({ name = 'spots', size = 24, color = '' }) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  const isActive = color === sodaColor;
  if (isActive) {
    switch (name) {
      case 'spots':
        return <TabBarSpotsActive size={size} color={color} />;
      case 'deals':
        return <TabBarDealsActive size={size} color={color} />;
      case 'favorites':
        return <TabBarFavoritesActive size={size} color={color} />;
      case 'inbox':
        return <TabBarInboxActive size={size} color={color} />;
      case 'profile':
        return <TabBarProfileActive size={size} color={color} />;
      default:
        return <TabBarSpotsActive size={size} color={color} />;
    }
  } else {
    switch (name) {
      case 'spots':
        return <TabBarSpotsDefault size={size} color={color} />;
      case 'deals':
        return <TabBarDealsDefault size={size} color={color} />;
      case 'favorites':
        return <TabBarFavoritesDefault size={size} color={color} />;
      case 'inbox':
        return <TabBarInboxDefault size={size} color={color} />;
      case 'profile':
        return <TabBarProfileDefault size={size} color={color} />;
      default:
        return <TabBarSpotsDefault size={size} color={color} />;
    }
  }
  return null;
}
