/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useEffect, useMemo } from 'react';
import { ColorSchemeName } from 'react-native';
import { InfluencerProvider } from '../hooks/useInfluencer';

import { useUser } from '../hooks/useUser';
import { useAppDispatch } from '../store/app.hooks';
import { setCode } from '../store/slices/influencer.slice';
import linking from './LinkingConfiguration';
import { LoggedInNavigator } from './LoggedIn/LoggedInNavigation';
import { LoggedOutNavigator } from './LoggedOut/LoggedOutNavigation';
import { RootStackParamList } from './RootNavigationProps';

function hasCodeInUrl() {
  const search: string | undefined = window?.location?.search;
  return search ? search.replace('?code=', '') : null;
}

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const theme = useMemo(() => (colorScheme === 'dark' ? DarkTheme : DefaultTheme), []);

  return (
    <NavigationContainer linking={linking} theme={theme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const user = useUser();
  const dispatch = useAppDispatch();
  const accessToken = user?.accessToken;

  useEffect(() => {
    /* this will set the code if the browser open a new window where the user is not logged in */
    const code = hasCodeInUrl();
    if (code) {
      dispatch(setCode(code));
    }
  }, []);

  return accessToken ? (
    <InfluencerProvider>
      <LoggedInNavigator />
    </InfluencerProvider>
  ) : (
    <LoggedOutNavigator />
  );
}
