import { FontAwesome } from '@expo/vector-icons';
import { AspectRatio, Box, Center, HStack, Image, Text, useToken } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { DealResponse } from '../../api/api';
import { ImageSize, getImageUrl } from '../utils';

function DealCardTypeBadge({ type }: { type: DealResponse['type'] }) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  return (
    <HStack
      bg={'white'}
      position={'absolute'}
      top={'0'}
      px={'2'}
      py={'1'}
      mx={'2'}
      my={'2'}
      borderRadius={30}
      space={2}>
      <FontAwesome size={14} name={type === 'reel' ? 'video-camera' : 'image'} color={sodaColor} />
      <Text fontSize={10} px={'0'} ml={'-1'} color={'soda.600'}>
        {type === 'reel' ? 'Reel' : 'Post'}
      </Text>
    </HStack>
  );
}

export function DealCardDiscountBadge({ discountPercent }: { discountPercent: DealResponse['discountPercent'] }) {
  return (
    <Center bg={'white'} position={'absolute'} top={'0'} px={'2'} pl={'3'} py={'1'} mx={'2'} my={'2'} borderRadius={30}>
      <Text fontSize={10} px={'0'} ml={'-1'} color={'soda.600'}>
        {Math.round(discountPercent * 100)}%
      </Text>
    </Center>
  );
}

export interface DealCardImageProps {
  media: DealResponse['media'];
  type: DealResponse['type'];
  children?: ReactNode;
}

export function DealCardImage({ media, type, children }: DealCardImageProps) {
  const image = useMemo(() => getImageUrl(media.gallery[0], ImageSize.LG), [media]);
  const source = image ? { uri: image } : undefined;
  return (
    <Box overflow={'hidden'} borderTopRadius={'2xl'}>
      <AspectRatio w={'100%'} ratio={1.25} minW={'100%'}>
        <Image source={source} alt="image of the Deal" />
      </AspectRatio>
      {/* <DealCardTypeBadge type={type} /> */}
      {children}
    </Box>
  );
}
