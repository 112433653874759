import { AspectRatio, Box, Heading, HStack, Image, Stack } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { SpotResponse } from '../../api/api';
import { softShadow } from '../../constants/shadow';
import { getImageUrl, ImageSize } from '../utils';
import SpotDetailAddress from './SpotDetailAddress';
import { SpotImage } from './SpotImage';

export function SpotDetailHeader({ spot, imageOverlay }: { spot: SpotResponse; imageOverlay?: ReactNode }) {
  const logo = useMemo(() => getImageUrl(spot.media.logo, ImageSize.LG), [spot]);
  return (
    <Box>
      <SpotImage media={spot.media} imageOverlay={imageOverlay}></SpotImage>
      <HStack alignItems={'flex-end'}>
        <Box w={'100px'} h={'100px'} backgroundColor={'white'} ml={4} mt={'-40px'} style={softShadow} rounded={'lg'}>
          <AspectRatio w="100%" ratio={1} minW="100%" rounded={'lg'} overflow={'hidden'}>
            <Image
              source={{
                uri: logo,
              }}
              alt="Logo of the Spot"
            />
          </AspectRatio>
        </Box>
        <Stack px={2} flex={1}>
          <Heading w={'100%'} isTruncated noOfLines={2} fontSize={'2xl'}>
            {spot.title}
          </Heading>
          <SpotDetailAddress address={spot.address}></SpotDetailAddress>
        </Stack>
      </HStack>
    </Box>
  );
}
