import { AspectRatio, Box, Center, Image } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { DealResponse } from '../../api/api';
import { getImageUrl, ImageSize } from '../utils';

export function ClaimActiveImage({ media, children }: { media: DealResponse['media']; children?: ReactNode }) {
  const image = useMemo(() => getImageUrl(media.gallery[0], ImageSize.LG), [media]);

  return (
    <Box overflow={'hidden'} borderTopRadius={'2xl'} position={'relative'}>
      <AspectRatio w={'100%'} ratio={1.25} minW={'100%'}>
        <Image
          source={{
            uri: image,
          }}
          alt="image of the Deal"
        />
      </AspectRatio>
      <Center position={'absolute'} w={'100%'} h={'100%'} backgroundColor={'rgba(0, 0, 0, 0.6)'}>
        {children}
      </Center>
    </Box>
  );
}
