import { Heading, KeyboardAvoidingView, Stack, Text } from 'native-base';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';

import LinkFooter from '../../components/Utils/LinkFooter';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function SignUpSuccessScreen({ navigation }: RootStackScreenProps<'SignUpSuccess'>) {
  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <ShadowBox buttonText="Zurück zu Login" onButtonPress={() => navigation.navigate('Login')}>
            <Heading textAlign={'left'} size={'sm'}>
              Anmeldung erfolgreich
            </Heading>
            <Text>
              Die Anmeldung war erfolgreich. Bitte bestätige deine E-Mail Adresse, indem Du auf den Link in der E-Mail
              klickst.
            </Text>
          </ShadowBox>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
