import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { SignInRequestDto, SignUpRequestDto } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import {
  activate,
  loadAccessTokenFromLocalStore,
  login,
  refreshAccessToken,
  resetPassword,
  setNewPassword,
  signUp,
} from '../../api/services/auth.service';

function setAccessToken(accessToken: string) {
  if (accessToken) {
    AsyncStorage.setItem('access_token', accessToken);
  } else {
    AsyncStorage.removeItem('access_token');
  }
  httpClient.accessToken = accessToken;
  return accessToken;
}

export const loginThunk = createAsyncThunk('user/login', (signInRequestDto: SignInRequestDto) => {
  return login(httpClient, signInRequestDto).then((accessToken) => setAccessToken(accessToken));
});

export const loadAccessTokenFromLocalStorageThunk = createAsyncThunk('user/loadAccessTokenFromLocalStorage', () => {
  return loadAccessTokenFromLocalStore().then((accessToken) => {
    httpClient.accessToken = accessToken;
    return accessToken;
  });
});

export const logoutThunk = createAsyncThunk('user/logout', (_, thunkApi) => {
  return AsyncStorage.removeItem('access_token').then(() => thunkApi.dispatch({ type: 'resetStore' })); // resetStore resets all reducers
});

export const signUpThunk = createAsyncThunk('user/signUp', (signUpRequestDto: SignUpRequestDto) => {
  return signUp(httpClient, signUpRequestDto);
});

export const activateThunk = createAsyncThunk('user/activate', (id: string) => {
  return activate(httpClient, id);
});

export const resetPasswordThunk = createAsyncThunk('user/resetPassword', (email: string) => {
  return resetPassword(httpClient, email);
});

export const refreshAccessTokenThunk = createAsyncThunk('user/refreshAccessToken', () => {
  return refreshAccessToken(httpClient).then((accessToken) => setAccessToken(accessToken.access_token));
});

export const setNewPasswordThunk = createAsyncThunk(
  'user/setNewPassword',
  ({ password, token }: { password: string; token: string }) => {
    return setNewPassword(httpClient, password, token);
  },
);

export enum Role {
  influencer = 'influencer',
  owner = 'owner',
  admin = 'admin',
}

export interface ApiUser {
  readonly id: string | null;
  readonly roles: Role[] | null;
}

export interface UserState {
  accessToken: string | null;
  data: ApiUser | null;
}

const initialState: UserState = {
  accessToken: null,
  data: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.data = jwtDecode(action.payload);
      httpClient.accessToken = state.accessToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAccessTokenFromLocalStorageThunk.fulfilled, (state, action: PayloadAction<string | null>) => {
        if (action.payload) {
          state.accessToken = action.payload;
          state.data = jwtDecode(action.payload);
        } else {
          state.accessToken = initialState.accessToken;
          state.data = initialState.data;
        }
      })
      .addCase(loginThunk.fulfilled, (state, action: PayloadAction<string>) => {
        if (action.payload) {
          state.accessToken = action.payload;
          state.data = jwtDecode(action.payload);
        } else {
          state.accessToken = initialState.accessToken;
          state.data = initialState.data;
        }
      })
      .addCase(refreshAccessTokenThunk.fulfilled, (state, action: PayloadAction<string>) => {
        state.accessToken = action.payload;
        state.data = jwtDecode(action.payload);
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.accessToken = null;
        state.data = null;
      });
  },
});

export const { setToken } = userSlice.actions;

export default userSlice.reducer;
