import { Center, Heading, HStack, KeyboardAvoidingView, Pressable, Stack, Text, useToast } from 'native-base';
import { useState } from 'react';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';
import { PasswordInput } from '../../components/StyledInput';

import LinkFooter from '../../components/Utils/LinkFooter';
import { useAppDispatch } from '../../store/app.hooks';
import { setNewPasswordThunk } from '../../store/slices/user.slice';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function NewPasswordScreen({ navigation, route }: RootStackScreenProps<'NewPassword'>) {
  const [password, setPassword] = useState('');
  const token = route.params.token;

  const dispatch = useAppDispatch();
  const toast = useToast();

  function newPassword() {
    if (!token) {
      return;
    }
    dispatch(setNewPasswordThunk({ password, token })).then((response) => {
      if (response.meta.requestStatus === 'rejected') {
        toast.show({
          id: response.meta.requestId,
          title: (response as any).error.message,
          placement: 'bottom-right',
          mx: 10,
        });
      }
    });
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <ShadowBox buttonText="Passwort bestätigen" onButtonPress={() => newPassword()}>
            <Heading textAlign={'left'} size={'sm'}>
              Neues Passwort wählen
            </Heading>
            <Text>Bitte gib Dein neues Passwort ein.</Text>
            <PasswordInput
              placeholder={'Passwort'}
              secureTextEntry={true}
              value={password}
              onChangeText={(text) => setPassword(text)}></PasswordInput>
          </ShadowBox>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => navigation.navigate('Login')}>
                <Text textDecorationLine={'underline'}>Zurück zu Login</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
