import { Center, Heading, HStack, Pressable, Stack, Text } from 'native-base';
import { DealResponse } from '../../api/api';
import { hardShadow } from '../../constants/shadow';
import { ArrowRightIcon } from '../Icons/Arrow-right';
import DealSpotCaption from './DealSpotCaption';
import { DealSummeryImage } from './DealSummeryImage';

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export interface DealSummeryProps {
  deal: DealResponse;
  onClick?: (id: string) => void;
}

export default function DealSummery({ deal, onClick }: DealSummeryProps) {
  return (
    <Pressable onPress={() => onClick?.(deal.id)}>
      <Stack w={362} rounded={'2xl'} backgroundColor={'white'} style={hardShadow}>
        <HStack>
          <Stack w={156}>
            <DealSummeryImage media={deal.media} type={deal.type}></DealSummeryImage>
          </Stack>
          <Stack flex={1} py={2} justifyContent={'space-between'}>
            <Heading size={'sm'} px={2} height={10} numberOfLines={2} isTruncated={true}>
              {deal.title}
            </Heading>
            <HStack alignItems={'stretch'} px={2} space={4} justifyContent={'space-between'}>
              <Stack>
                <HStack space={2}>
                  <Text textDecorationLine={'line-through'} color={'city.600'}>
                    {formatter.format(deal.regularPrice)}
                  </Text>
                  {/* <Text color={'city.700'}>{Math.round(deal.discountPercent * 100)}%</Text> */}
                </HStack>
                <Text fontSize={'2xl'} color={'soda.600'}>
                  {formatter.format(deal.reducedPrice)}
                </Text>
              </Stack>
              <Stack justifyContent={'flex-end'} pb={1}>
                <Center rounded={'xl'} p={2} backgroundColor={'soda.600'}>
                  <ArrowRightIcon width={20} height={20} color={'white'} />
                </Center>
              </Stack>
            </HStack>
          </Stack>
        </HStack>
        {deal.spotCaption ? (
          <Stack bgColor={'city.50'} py={1} borderBottomRadius={'2xl'}>
            <DealSpotCaption spotCaption={deal.spotCaption} />
          </Stack>
        ) : null}
      </Stack>
    </Pressable>
  );
}
