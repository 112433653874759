import React, { ReactNode, useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export interface SlideUpAnimationProps {
  children: ReactNode;
}

export default function SlideUpAnimation({ children }: SlideUpAnimationProps) {
  const fadeValue = useRef(new Animated.Value(0)).current;
  const slideUpValue = useRef(new Animated.Value(20)).current;

  useEffect(() => {
    Animated.timing(fadeValue, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  }, [fadeValue]);

  useEffect(() => {
    Animated.timing(slideUpValue, {
      toValue: 0,
      useNativeDriver: true,
    }).start();
  }, [slideUpValue]);

  return (
    <Animated.View style={{ opacity: fadeValue, transform: [{ translateY: slideUpValue }] }}>{children}</Animated.View>
  );
}
