import { createNativeStackNavigator } from '@react-navigation/native-stack';
import InfluencerSignUpScreen from './InfluencerSignUpScreen';
import InfluencerVerificationScreen from './InfluencerVerificationScreen';
import InstagramLoginScreen from './InstagramLoginScreen';
import InstagramLoginVerificationScreen from './InstagramLoginVerfificationScreen';

export type NotVerfiedParamList = {
  InfluencerSignUp: undefined;
  InfluencerVerification: undefined;
  InstagramLogin: undefined;
  InstagramVerification: undefined;
};

const Stack = createNativeStackNavigator<NotVerfiedParamList>();

export function NotVerfiedNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={'InfluencerSignUp'}
      screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen name={'InfluencerSignUp'} component={InfluencerSignUpScreen} options={{ headerShown: false }} />
      <Stack.Screen name={'InstagramLogin'} component={InstagramLoginScreen} options={{ headerShown: false }} />
      <Stack.Screen
        name={'InstagramVerification'}
        component={InstagramLoginVerificationScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={'InfluencerVerification'}
        component={InfluencerVerificationScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}
