import { environment } from '../../constants/environment';
import { FavoriteAddRequestDto, FavoriteRemoveRequestDto, FavoriteResponse } from '../api';
import { HttpClient } from '../httpClient';
import { PaginatedResponse } from '../paginated.interface';

const favoriteEndpoint = `${environment.endpoint}/favorite`;

export function favoriteLoadList(httpClient: HttpClient) {
  return httpClient.get<PaginatedResponse<FavoriteResponse>>(`${favoriteEndpoint}/me`);
}

export function favoriteAdd(httpClient: HttpClient, favoriteAddDto: FavoriteAddRequestDto) {
  return httpClient.post<FavoriteResponse>(`${favoriteEndpoint}`, favoriteAddDto);
}

export function favoriteRemove(httpClient: HttpClient, favoriteRemoveDto: FavoriteRemoveRequestDto) {
  return httpClient.post<boolean>(`${favoriteEndpoint}/remove`, favoriteRemoveDto);
}
