import { Center, Heading, HStack, Image, Stack, Text } from 'native-base';
import { TicketResponse } from '../../api/api';
import logo from './../../assets/images/spotliked-logo-icon-small.png';

export interface TicketSummeryProps {
  ticket: TicketResponse;
}

export default function TicketSummery({ ticket }: TicketSummeryProps) {
  const lastMessage = ticket.messages.at(-1);
  return (
    <HStack
      justifyContent={'flex-start'}
      alignItems={'center'}
      flex={1}
      flexGrow={1}
      maxW={'100%'}
      overflow={'hidden'}
      backgroundColor={'city.100'}
      rounded={'xl'}
      py={'1'}
      px={'2'}>
      <Center>
        <Image source={logo} w={'50px'} h={'50px'} rounded={'xl'} resizeMode={'cover'} alt={'Logo of Spotliked'} />
      </Center>

      <Stack p={'2'} flexShrink={1}>
        <Heading size={'sm'} isTruncated noOfLines={1}>
          {ticket.subject}
        </Heading>
        <Text isTruncated noOfLines={1} fontWeight={'400'} color={'city.500'}>
          {lastMessage?.text}
        </Text>
      </Stack>
    </HStack>
  );
}
