import { FontAwesome } from '@expo/vector-icons';
import { HStack, Pressable, Stack, Text } from 'native-base';
import { Linking } from 'react-native';
import { SpotResponse } from '../../api/api';

const instagramUrl = `https://www.instagram.com/`;

export default function SpotCardContact({ contact }: { contact: SpotResponse['contact'] }) {
  function openSite(site: string) {
    Linking.openURL(site);
  }

  function openInstagram(instagramId: string) {
    Linking.openURL(`${instagramUrl}/${instagramId}`);
  }

  return (
    <Stack space={2}>
      <Text fontSize={'md'} fontWeight={'bold'}>
        Kontakt
      </Text>
      {contact?.instagramId ? (
        <HStack
          justifyContent={'space-between'}
          alignItems={'center'}
          px={4}
          py={2}
          backgroundColor={'city.100'}
          rounded={4}>
          <Stack>
            <Text color={'city.600'} fontSize={'xs'}>
              Instagram
            </Text>
            <Text>{contact!.instagramId}</Text>
          </Stack>
          <Pressable onPress={() => openInstagram(contact.instagramId!)}>
            <FontAwesome name="instagram" size={30} style={{ opacity: 0.4 }}></FontAwesome>
          </Pressable>
        </HStack>
      ) : null}
      {contact?.site ? (
        <HStack
          justifyContent={'space-between'}
          alignItems={'center'}
          px={4}
          py={2}
          backgroundColor={'city.100'}
          rounded={4}>
          <Stack>
            <Text color={'city.600'} fontSize={'xs'}>
              Website
            </Text>
            <Text>{contact!.site}</Text>
          </Stack>
          <Pressable onPress={() => openSite(contact.site!)}>
            <FontAwesome name="home" size={30} style={{ opacity: 0.4 }}></FontAwesome>
          </Pressable>
        </HStack>
      ) : null}
      {contact?.phone ? (
        <HStack
          justifyContent={'space-between'}
          alignItems={'center'}
          px={4}
          py={2}
          backgroundColor={'city.100'}
          rounded={4}>
          <Stack>
            <Text color={'city.600'} fontSize={'xs'}>
              Telefonnummer
            </Text>
            <Text>{contact!.phone}</Text>
          </Stack>
          <Pressable>
            <FontAwesome name="phone" size={30} style={{ opacity: 0.4 }}></FontAwesome>
          </Pressable>
        </HStack>
      ) : null}
      {contact?.mail ? (
        <HStack
          justifyContent={'space-between'}
          alignItems={'center'}
          px={4}
          py={2}
          backgroundColor={'city.100'}
          rounded={4}>
          <Stack>
            <Text color={'city.600'} fontSize={'xs'}>
              Email
            </Text>
            <Text>{contact!.mail}</Text>
          </Stack>
          <Pressable>
            <FontAwesome name="envelope" size={30} style={{ opacity: 0.4 }}></FontAwesome>
          </Pressable>
        </HStack>
      ) : null}
    </Stack>
  );
}
