import { FontAwesome } from '@expo/vector-icons';
import { Center, Heading, HStack, Pressable, Spinner, Stack, Text, useToast } from 'native-base';
import { useEffect } from 'react';
import { SpotlikedLogo } from '../../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../../components/ShadowBox';

import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { influencerSendAuthCodeThunk } from '../../../store/slices/influencer.slice';
import { logoutThunk } from '../../../store/slices/user.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function InstagramLoginVerificationScreen({
  navigation,
  route,
}: RootStackScreenProps<'InstagramVerification'>) {
  const dispatch = useAppDispatch();
  const storedCode = useAppSelector((state) => state.influencer.code);

  const toast = useToast();

  async function logout() {
    await dispatch(logoutThunk());
    navigation.navigate('Login');
  }

  useEffect(() => {
    const code = (route.params as any)?.code || storedCode;
    if (code) {
      dispatch(influencerSendAuthCodeThunk(code)).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          navigation.navigate('InfluencerVerification');
        } else {
          toast.show({
            id: response.meta.requestId,
            title: (response as any).error.message,
            placement: 'bottom-right',
            mx: 10,
          });
          navigation.navigate('InstagramLogin');
        }
      });
    }
  }, []);

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} py={8} alignItems={'center'}>
      <SpotlikedLogo />
      <Stack space={2}>
        <ShadowBox>
          <Stack alignItems={'center'} space={4}>
            <Heading textAlign={'left'} size={'sm'}>
              Instagram Account wird verbunden
            </Heading>
            <Stack position={'relative'}>
              <Spinner size={120} color={'soda.500'} />
              <Center position={'absolute'} w={'100%'} h={'100%'}>
                <FontAwesome size={60} name="instagram"></FontAwesome>
              </Center>
            </Stack>
          </Stack>
        </ShadowBox>

        <Center>
          <HStack p={3} space={3}>
            <Pressable onPress={() => logout()}>
              <Text textDecorationLine={'underline'}>Logout</Text>
            </Pressable>
          </HStack>
        </Center>
      </Stack>
    </Stack>
  );
}
