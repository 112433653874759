import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Box, Center, HStack, Pressable, ScrollView, Stack, Text, useToken } from 'native-base';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { DealResponse, SpotResponse } from '../../api/api';
import { useUser } from '../../hooks/useUser';
import { useAppDispatch } from '../../store/app.hooks';
import { setDeal } from '../../store/slices/deal-detail.slice';
import { favoriteAddThunk, favoriteLoadListThunk, favoriteRemoveThunk } from '../../store/slices/favorite.slice';
import { addFavorite, removeFavorite } from '../../store/slices/spot-detail.slice';
import SlideUpAnimation from '../Animations/SlideUpAnimation';
import DealCard from '../Deal/DealCard';
import SpotCardContact from './SpotCardContact';
import { SpotDetailHeader } from './SpotDetailHeader';

function SpotDetailInner({ spot }: { spot: SpotResponse }) {
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [isFavorite, setIsFavorite] = useState(false);
  const [sodaColor] = useToken('colors', ['soda.600']);

  const imageOverlay = useMemo(
    () => (
      <Box position={'absolute'} top={0} right={0} p={4}>
        <Pressable onPress={() => toggleFavorite(spot)}>
          <Center backgroundColor={'white'} rounded={'full'} p={2}>
            {isFavorite ? (
              <FontAwesome size={18} name={'heart'} color={sodaColor}></FontAwesome>
            ) : (
              <FontAwesome size={18} name={'heart-o'} color={sodaColor}></FontAwesome>
            )}
          </Center>
        </Pressable>
      </Box>
    ),
    [isFavorite, spot],
  );

  const toggleFavorite = useCallback(
    (spot: SpotResponse) => {
      const userId: string = user!.data!.id!;
      isFavorite ? dispatch(removeFavorite(userId)) : dispatch(addFavorite(userId));
      const favoriteActionThunk = isFavorite ? dispatch(favoriteRemoveThunk(spot)) : dispatch(favoriteAddThunk(spot));
      favoriteActionThunk.finally(() => {
        dispatch(favoriteLoadListThunk());
      });
    },
    [isFavorite],
  );

  const openDealDetail = useCallback((deal: DealResponse) => {
    dispatch(setDeal(deal));
    navigation.navigate('DealDetail', { dealId: deal.id });
  }, []);

  useEffect(() => {
    const userId = user!.data!.id!;
    const isFavorite = Boolean(spot.favorites[userId]);
    setIsFavorite(isFavorite);
  }, [user, spot]);

  return (
    <Box w={'100%'}>
      <SpotDetailHeader spot={spot} imageOverlay={imageOverlay}></SpotDetailHeader>
      <Stack py={4} px={4} space={4}>
        <Stack>
          <Text fontSize={'md'} fontWeight={'bold'}>
            Beschreibung
          </Text>
          <Text pt={2} color={'city.600'}>
            {spot.content?.description}
          </Text>
        </Stack>

        <Stack>
          <Text fontSize={'md'} fontWeight={'bold'}>
            Öffnungszeiten
          </Text>
          <Text pt={2} color={'city.600'}>
            {spot.content?.openingHours}
          </Text>
        </Stack>
      </Stack>

      <ScrollView w={'100%'} horizontal={true}>
        <HStack flexWrap={'nowrap'} p={4}>
          {spot.deals?.map((deal) => (
            <Stack key={deal.id} pr={2}>
              <DealCard deal={deal} showSpotCaption={false} onClick={() => openDealDetail(deal)} />
            </Stack>
          ))}
        </HStack>
      </ScrollView>

      <Stack py={4} px={4} space={4}>
        <SpotCardContact contact={spot.contact}></SpotCardContact>
      </Stack>

      <Stack py={4} px={4} space={4}>
        <Stack>
          <Text fontSize={'md'} fontWeight={'bold'}>
            Rechtliches und Bedingungen
          </Text>
          <Text pt={2} color={'city.600'}>
            {spot.legal?.imprint}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}

export interface SpotDetailProps {
  spot: SpotResponse;
  children?: ReactNode;
}

export default function SpotDetail({ spot }: SpotDetailProps) {
  const windowDimensions = useWindowDimensions();

  return (
    <>
      {windowDimensions.width > 900 ? (
        <Stack alignItems={'center'} py={4}>
          <Box w={900}>
            <SlideUpAnimation>
              <SpotDetailInner spot={spot}></SpotDetailInner>
            </SlideUpAnimation>
          </Box>
        </Stack>
      ) : (
        <ScrollView w={'100%'} h={'100%'} pb={8}>
          <SlideUpAnimation>
            <SpotDetailInner spot={spot}></SpotDetailInner>
          </SlideUpAnimation>
        </ScrollView>
      )}
    </>
  );
}
