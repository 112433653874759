import { Box } from 'native-base';
import { useCallback } from 'react';
import { SpotResponse } from '../../../api/api';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function MapScreen({ navigation, route }: RootStackScreenProps<'Map'>) {
  const apiResponse = useAppSelector((state) => state.search.api_response);
  const dispatch = useAppDispatch();

  const openSpotDetail = useCallback((spot: SpotResponse) => {
    navigation.navigate('SpotDetail', { spotId: spot.id });
  }, []);

  return <Box w={'100%'} h={'100%'}></Box>;
}
