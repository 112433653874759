import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ActivateScreen from './ActivateScreen';
import LoginScreen from './LoginScreen';
import NewPasswordScreen from './NewPasswordScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import SignUpScreen from './SignUpScreen';
import SignUpSuccessScreen from './SignUpSuccessScreen';

export type LoggedOutParamList = {
  Login: undefined;
  SignUp: undefined;
  SignUpSuccess: undefined;
  ResetPassword: undefined;
  NewPassword: { token: string };
  Activate: { id: string };
};

const Stack = createNativeStackNavigator<LoggedOutParamList>();

export function LoggedOutNavigator() {
  return (
    <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen name="Login" component={LoginScreen} options={{ title: 'Anmelden', headerShown: false }} />
      <Stack.Screen name="SignUp" component={SignUpScreen} options={{ title: 'Registrieren', headerShown: false }} />
      <Stack.Screen
        name="SignUpSuccess"
        component={SignUpSuccessScreen}
        options={{ title: 'Registrieren erfolgreich', headerShown: false }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPasswordScreen}
        options={{ title: 'Passwort zurücksetzen', headerShown: false }}
      />
      <Stack.Screen
        name="NewPassword"
        component={NewPasswordScreen}
        options={{ title: 'Neues Passwort wählen', headerShown: false }}
      />
      <Stack.Screen
        name="Activate"
        component={ActivateScreen}
        options={{ title: 'Account wird aktiviert', headerShown: false }}
      />
    </Stack.Navigator>
  );
}
