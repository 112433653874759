import { ResizeMode, Video } from 'expo-av';
import { Button, Center, HStack, Image, ScrollView, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';
import { TouchableHighlight } from 'react-native';
import { InstagramMediaResponseDto } from '../../../api/api';
import { useAppDispatch } from '../../../store/app.hooks';
import { setSelectedMedia } from '../../../store/slices/claim.slice';
import { influencerMyInstagramMediaThunk } from '../../../store/slices/influencer.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

const postSize = 150;

export default function SelectPostScreen({ navigation }: RootStackScreenProps<'SelectPost'>) {
  const [mediaList, setMediaList] = useState<InstagramMediaResponseDto>();
  const [selected, setSelected] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(influencerMyInstagramMediaThunk()).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const payload = response.payload as InstagramMediaResponseDto;
        setMediaList(payload);
      }
    });
  }, []);

  const save = () => {
    dispatch(setSelectedMedia(selected));
    navigation.pop();
  };

  return (
    <Stack space={2} p={2} h={'100%'}>
      <Button onPress={() => save()}>Auswählen</Button>
      <ScrollView py={4} flex={1}>
        <HStack alignItems={'center'} flexWrap={'wrap'} space={2} pb={8}>
          {mediaList?.data.map((data) =>
            data.media_type === 'IMAGE' ? (
              <TouchableHighlight
                key={data.id}
                underlayColor={'rgba(255,255,255,0.4)'}
                onPress={() => setSelected(data.id)}>
                <Center p={2} borderColor={'soda.600'} borderWidth={selected === data.id ? 1 : 0}>
                  <Image size={postSize} alt={data.caption} source={{ uri: data.media_url }}></Image>
                  <Text noOfLines={2} isTruncated={true} w={postSize}>
                    {data.caption}
                  </Text>
                </Center>
              </TouchableHighlight>
            ) : data.media_type === 'VIDEO' ? (
              <TouchableHighlight
                key={data.id}
                underlayColor={'rgba(255,255,255,0.4)'}
                onPress={() => setSelected(data.id)}>
                <Center p={2} borderColor={'soda.600'} borderWidth={selected === data.id ? 1 : 0}>
                  <Video
                    style={{ width: postSize, height: postSize }}
                    videoStyle={{ width: postSize, height: postSize }}
                    isMuted={true}
                    shouldPlay={true}
                    resizeMode={ResizeMode.COVER}
                    source={{ uri: data.media_url }}></Video>
                  <Text noOfLines={2} isTruncated={true} w={postSize}>
                    {data.caption}
                  </Text>
                </Center>
              </TouchableHighlight>
            ) : null,
          )}
        </HStack>
      </ScrollView>
    </Stack>
  );
}
