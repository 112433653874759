import { FontAwesome } from '@expo/vector-icons';
import { Center, Heading, HStack, Pressable, Stack, Text } from 'native-base';
import { Linking } from 'react-native';
import { SpotlikedLogo } from '../../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../../components/ShadowBox';
import { environment } from '../../../constants/environment';

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { logoutThunk } from '../../../store/slices/user.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

const instagramLoginUrl = environment.endpoint + '/influencer/instagram';

export default function InstagramLoginScreen({ navigation }: RootStackScreenProps<'InstagramLogin'>) {
  const dispatch = useAppDispatch();
  const storedCode = useAppSelector((state) => state.influencer.code);

  function openInstagramLogin() {
    Linking.openURL(instagramLoginUrl);
  }

  async function logout() {
    await dispatch(logoutThunk());
    navigation.navigate('Login');
  }

  useEffect(() => {
    if (storedCode) {
      navigation.navigate('InstagramVerification');
    }
  }, []);

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} py={8} alignItems={'center'}>
      <SpotlikedLogo />
      <Stack space={2}>
        <ShadowBox buttonText="Login mit Instagram" onButtonPress={() => openInstagramLogin()}>
          <Stack alignItems={'center'} space={4}>
            <Heading textAlign={'left'} size={'sm'}>
              Verbinde Deinen Instagram Account
            </Heading>
            <FontAwesome size={60} name="instagram"></FontAwesome>
          </Stack>
        </ShadowBox>

        <Center>
          <HStack p={3} space={3}>
            <Pressable onPress={() => logout()}>
              <Text textDecorationLine={'underline'}>Logout</Text>
            </Pressable>
          </HStack>
        </Center>
      </Stack>
    </Stack>
  );
}
