import { environment } from '../../constants/environment';
import { SpotResponse } from '../api';
import { HttpClient } from '../httpClient';
import { PaginatedResponse } from '../paginated.interface';

const searchEndpoint = `${environment.endpoint}/search`;
const searchSpotEndpoint = `${searchEndpoint}/spot`;

export function searchSpot(
  httpClient: HttpClient,
  { search, pagination }: { search?: string | null; pagination?: number | null },
) {
  const params: any = {};
  if (search) {
    params.search = search;
  }
  if (pagination && pagination !== 1) {
    params.skip = pagination;
  }
  return httpClient.get<PaginatedResponse<SpotResponse>>(searchSpotEndpoint, params);
}

export function searchTag(httpClient: HttpClient, { search }: { search: string }) {
  return httpClient.get<PaginatedResponse<{ id: string; title: string }>>(`${searchEndpoint}/tag`, {
    search,
  });
}
