import { Box, HStack, ScrollView, Stack, Text } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, TouchableHighlight } from 'react-native';
import { DealResponse, SpotResponse } from '../../../../api/api';
import DealCard from '../../../../components/Deal/DealCard';
import DealSummery from '../../../../components/Deal/DealSummery';
import SpotSummery from '../../../../components/Spot/SpotSummery';
import { softShadow } from '../../../../constants/shadow';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { setDeal } from '../../../../store/slices/deal-detail.slice';
import { loadHomeSectionsThunk } from '../../../../store/slices/main-page.slice';
import { HomeNavigationScreenProps } from './HomeNavigation';

export default function HomeScreen({ navigation }: HomeNavigationScreenProps<'Home'>) {
  const sections = useAppSelector((state) => state.main.api_response);
  const dispatch = useAppDispatch();

  const openDealDetail = useCallback((deal: DealResponse) => {
    dispatch(setDeal(deal));
    navigation.getParent()!.navigate('DealDetail', { dealId: deal.id });
  }, []);

  const openSpotDetail = useCallback((spot: SpotResponse) => {
    navigation.getParent()!.navigate('SpotDetail', { spotId: spot.id });
  }, []);

  const [sectionOneList, setSectionOneList] = useState<any[]>([]);
  const [sectionTwoList, setSectionTwoList] = useState<any[]>([]);
  const [sectionThreeList, setSectionThreeList] = useState<any[]>([]);
  const [sectionFourList, setSectionFourList] = useState<any[]>([]);

  useEffect(() => {
    if (sections?.sections) {
      const s = sections.sections;
      setSectionOneList(s[0].items);
      setSectionTwoList(s[1].items);
      setSectionThreeList(s[2].items);
      setSectionFourList(s[3].items);
    }
  }, [sections]);

  useEffect(() => {
    dispatch(loadHomeSectionsThunk());
  }, []);

  return (
    <Box h={'100%'} position={'relative'} alignItems={'center'}>
      <Box h={'100%'} w={'100%'} maxW={900}>
        <ScrollView py={2}>
          <Stack px={2}>
            <Stack py={10} w={'100%'} justifyContent={'center'} bgColor={'soda.500'} rounded={'2xl'}>
              <Stack alignItems={'center'}>
                <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                  Neu hinzugefügte Deals
                </Text>
              </Stack>
              <ScrollView style={{ width: '100%' }} horizontal={true}>
                <HStack flexWrap={'nowrap'} p={4} space={2}>
                  {sectionOneList.map((deal) => (
                    <Stack key={deal.id} py={2}>
                      <DealCard showDescription={false} width={214} deal={deal} onClick={() => openDealDetail(deal)} />
                    </Stack>
                  ))}
                </HStack>
              </ScrollView>
            </Stack>
          </Stack>
          <Stack pt={6} px={2}>
            <Stack>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Unsere Spots
              </Text>
            </Stack>
            <Stack space={1}>
              {sectionTwoList.map((spot) => (
                <Stack key={spot.id} py={2}>
                  <Pressable onPress={() => openSpotDetail(spot)}>
                    <SpotSummery spot={spot} />
                  </Pressable>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack pt={6}>
            <Stack bgColor={'soda.500'} p={6}>
              <Text textAlign={'center'} fontSize={'2xl'} color={'white'} fontWeight={'bold'}>
                Freunde werben
              </Text>
            </Stack>
          </Stack>
          <Stack pt={6} px={2}>
            <Stack>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Top Deals
              </Text>
            </Stack>
            <ScrollView style={{ width: '100%' }} horizontal={true}>
              <HStack flexWrap={'nowrap'} p={2} space={2}>
                {sectionThreeList.map((deal) => (
                  <Stack key={deal.id} py={2}>
                    <DealSummery deal={deal} onClick={() => openDealDetail(deal)} />
                  </Stack>
                ))}
              </HStack>
            </ScrollView>
          </Stack>
          <Stack pt={6} px={2}>
            <Stack>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Meist gesucht
              </Text>
            </Stack>
            <HStack flexWrap={'wrap'} space={2}>
              {sectionFourList.map((searchTag) => (
                <TouchableHighlight key={searchTag.id}>
                  <Box
                    mt={2}
                    p={2}
                    borderWidth={1}
                    borderStyle={'solid'}
                    borderColor={'soda.200'}
                    style={softShadow}
                    rounded={'xl'}>
                    <Text>{searchTag.title}</Text>
                  </Box>
                </TouchableHighlight>
              ))}
            </HStack>
          </Stack>
          <Stack pt={6}>
            <Stack bgColor={'soda.500'} p={6}>
              <Text textAlign={'center'} fontSize={'2xl'} color={'white'} fontWeight={'bold'}>
                Hier fehlt dir noch ein Laden? Sag uns Bescheid.
              </Text>
            </Stack>
          </Stack>
        </ScrollView>
      </Box>
    </Box>
  );
}
