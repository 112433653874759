import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpClient } from '../../api/httpClient';
import { getHomeSections } from '../../api/services/home-section.service';

export const loadHomeSectionsThunk = createAsyncThunk('home/load-sections', () => {
  return getHomeSections(httpClient);
});

export interface MainPageState {
  api_response: any;
}

const initialState: MainPageState = {
  api_response: null,
};

export const mainPageSlice = createSlice({
  name: 'main-page',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadHomeSectionsThunk.fulfilled, (state, action: PayloadAction<any>) => {
      state.api_response = action.payload;
    });
  },
});

export const {} = mainPageSlice.actions;

export default mainPageSlice.reducer;
