import { AspectRatio, Box, Image } from 'native-base';
import { useMemo } from 'react';
import { MediaFileDto } from '../../api/api';
import { ImageSize, getImageUrl } from '../utils';

export function DealCardSpotCaptionImage({ media }: { media: MediaFileDto }) {
  const image = useMemo(() => getImageUrl(media, ImageSize.SM), [media]);
  const source = image ? { uri: image } : undefined;
  return (
    <Box overflow={'hidden'} w={6}>
      <AspectRatio ratio={1} w={'100%'} minW={'100%'}>
        <Image source={source} alt="image of the Spot of the Deal" rounded={'lg'} />
      </AspectRatio>
    </Box>
  );
}
