import { AspectRatio, Box, Image } from 'native-base';
import { useMemo } from 'react';
import { DealResponse } from '../../api/api';
import { ImageSize, getImageUrl } from '../utils';

export function DealSummeryImage({ media, type }: { media: DealResponse['media']; type: DealResponse['type'] }) {
  const image = useMemo(() => getImageUrl(media.gallery[0], ImageSize.LG), [media]);
  const source = image ? { uri: image } : undefined;
  return (
    <Box overflow={'hidden'} borderTopLeftRadius={'2xl'}>
      <AspectRatio w={'100%'} ratio={1.25} minW={'100%'}>
        <Image source={source} alt="image of the Deal" />
      </AspectRatio>
    </Box>
  );
}
