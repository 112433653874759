import Svg, { Path } from 'react-native-svg';

export function TabBarSpotsDefault({ size = 24, color = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4654 9.29415C20.5115 11.3333 20.5115 14.6393 18.4654 16.6784L18.4645 16.6795L12.6643 22.501L11.3308 23.8298C11.2215 23.9388 11.0732 24 10.9185 24C10.6112 24 10.3594 23.7633 10.3372 23.4631L10.3355 23.4198V17.046C11.2144 16.7428 12.0064 16.2542 12.6645 15.6273L12.6643 19.1941L16.7348 15.1216L16.7328 15.1197C16.7616 15.093 16.7905 15.0656 16.8184 15.0375C17.9553 13.9046 17.9553 12.0679 16.8184 10.9351C15.6818 9.80226 13.8386 9.80226 12.7019 10.9351C12.6192 11.0175 12.5425 11.1036 12.4719 11.1929L12.4315 11.2458C12.2192 11.5276 11.8809 11.7099 11.5 11.7099C10.8569 11.7099 10.3355 11.1904 10.3355 10.5496C10.3355 10.3122 10.4071 10.0914 10.5299 9.9075L10.5684 9.8534C10.7154 9.65769 10.8776 9.47113 11.0552 9.29415C13.1014 7.25502 16.4189 7.25502 18.4654 9.29415ZM12.0814 0C12.3887 0 12.6405 0.236678 12.6627 0.536885L12.6644 0.580165V6.95406C11.7855 7.25722 10.9935 7.74575 10.3354 8.37274L10.3356 4.80597L6.265 8.87844L6.2673 8.88026C6.23821 8.90698 6.20957 8.93442 6.18135 8.96253C5.04457 10.0954 5.04457 11.9321 6.18135 13.0649C7.31814 14.1978 9.16126 14.1978 10.298 13.0649C10.3807 12.9825 10.4574 12.8964 10.528 12.8071L10.5684 12.7542C10.7807 12.4724 11.119 12.2901 11.5 12.2901C12.143 12.2901 12.6644 12.8096 12.6644 13.4504C12.6644 13.6878 12.5928 13.9086 12.47 14.0925L12.4315 14.1466C12.2845 14.3423 12.1223 14.5289 11.9447 14.7059C9.89848 16.745 6.58089 16.745 4.53467 14.7059C2.48844 12.6668 2.48844 9.3607 4.53467 7.32156L10.3356 1.49903L11.6691 0.170191C11.7784 0.0612364 11.9268 0 12.0814 0Z"
        fill={color}
      />
    </Svg>
  );
}

export function TabBarSpotsActive({ size = 24, color = '#00AAF2', strokeColor = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18.4654 9.29415C20.5115 11.3333 20.5115 14.6393 18.4654 16.6784L18.4645 16.6795L12.6643 22.501L11.3308 23.8298C11.2215 23.9388 11.0732 24 10.9185 24C10.6112 24 10.3594 23.7633 10.3372 23.4631L10.3355 23.4198V15.8009C8.41827 16.6338 6.10302 16.2688 4.53467 14.7059C2.48844 12.6668 2.48844 9.3607 4.53467 7.32157L10.3356 1.49903L11.6691 0.170191C11.7784 0.0612364 11.9268 0 12.0814 0C12.3887 0 12.6405 0.236678 12.6627 0.536885L12.6644 0.580165V8.19915C14.5816 7.36623 16.8968 7.73123 18.4654 9.29415Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4654 9.29415C20.5115 11.3333 20.5115 14.6393 18.4654 16.6784L18.4645 16.6795L12.6643 22.501L11.3308 23.8298C11.2215 23.9388 11.0732 24 10.9185 24C10.6112 24 10.3594 23.7633 10.3372 23.4631L10.3355 23.4198V17.046C11.2144 16.7428 12.0064 16.2542 12.6645 15.6273L12.6643 19.1941L16.7348 15.1216L16.7328 15.1197C16.7616 15.093 16.7905 15.0656 16.8184 15.0375C17.9553 13.9046 17.9553 12.0679 16.8184 10.9351C15.6818 9.80226 13.8386 9.80226 12.7019 10.9351C12.6192 11.0175 12.5425 11.1036 12.4719 11.1929L12.4315 11.2458C12.2192 11.5276 11.8809 11.7099 11.5 11.7099C10.8569 11.7099 10.3355 11.1904 10.3355 10.5496C10.3355 10.3122 10.4071 10.0914 10.5299 9.9075L10.5684 9.8534C10.7154 9.65769 10.8776 9.47113 11.0552 9.29415C13.1014 7.25502 16.4189 7.25502 18.4654 9.29415ZM12.0814 0C12.3887 0 12.6405 0.236678 12.6627 0.536885L12.6644 0.580165V6.95406C11.7855 7.25722 10.9935 7.74575 10.3354 8.37274L10.3356 4.80597L6.265 8.87844L6.2673 8.88026C6.23821 8.90698 6.20957 8.93442 6.18135 8.96253C5.04457 10.0954 5.04457 11.9321 6.18135 13.0649C7.31814 14.1978 9.16126 14.1978 10.298 13.0649C10.3807 12.9825 10.4574 12.8964 10.528 12.8071L10.5684 12.7542C10.7807 12.4724 11.119 12.2901 11.5 12.2901C12.143 12.2901 12.6644 12.8096 12.6644 13.4504C12.6644 13.6878 12.5928 13.9086 12.47 14.0925L12.4315 14.1466C12.2845 14.3423 12.1223 14.5289 11.9447 14.7059C9.89848 16.745 6.58089 16.745 4.53467 14.7059C2.48844 12.6668 2.48844 9.3607 4.53467 7.32156L10.3356 1.49903L11.6691 0.170191C11.7784 0.0612364 11.9268 0 12.0814 0Z"
        fill={strokeColor}
      />
    </Svg>
  );
}
