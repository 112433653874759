import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpotResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { spotLoad } from '../../api/services/spot.service';

export const spotLoadThunk = createAsyncThunk('sopt/load', (spotId: string) => {
  return spotLoad(httpClient, spotId);
});

export interface SpotDetailState {
  api_response: SpotResponse | null;
}

const initialState: SpotDetailState = {
  api_response: null,
};

export const spotDetailSlice = createSlice({
  name: 'spot',
  initialState,
  reducers: {
    addFavorite(state, action: PayloadAction<string>) {
      (state.api_response?.favorites as any)[action.payload] = Date.now();
    },
    removeFavorite(state, action: PayloadAction<string>) {
      delete (state.api_response?.favorites as any)[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spotLoadThunk.fulfilled, (state, action: PayloadAction<SpotResponse>) => {
      state.api_response = action.payload;
    });
  },
});

export const { addFavorite, removeFavorite } = spotDetailSlice.actions;

export default spotDetailSlice.reducer;
