import { FontAwesome } from '@expo/vector-icons';
import { HStack, useToken } from 'native-base';
import { ComponentProps, useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';

const withoutOutline: any = Platform.OS === 'web' ? { outlineStyle: 'none' } : {};

export interface InputWithIconProps extends ComponentProps<typeof TextInput> {
  iconName?: ComponentProps<typeof FontAwesome>['name'];
}

export function InputWithIcon({ iconName, ...props }: InputWithIconProps) {
  const [isFocus, setIsFocus] = useState(false);
  const [sodaColor] = useToken('colors', ['soda.600']);

  const inputRef = useRef<TextInput | null>(null);

  return (
    <HStack borderBottomWidth={1} px={1} alignItems={'center'} borderBottomColor={isFocus ? 'soda.600' : 'black'}>
      <FontAwesome
        size={20}
        name={iconName}
        color={isFocus ? sodaColor : 'black'}
        onPress={() => inputRef.current!.focus()}
      />
      <TextInput
        ref={inputRef}
        style={[{ padding: 6, marginLeft: 6, flexGrow: 1 }, withoutOutline]}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        {...props}
      />
    </HStack>
  );
}

export function PasswordInput(props: ComponentProps<typeof TextInput>) {
  const [isFocus, setIsFocus] = useState(false);
  const [sodaColor] = useToken('colors', ['soda.600']);
  const [showPassword, setShowPassword] = useState(true);

  const inputRef = useRef<TextInput | null>(null);

  return (
    <HStack borderBottomWidth={1} px={1} alignItems={'center'} borderBottomColor={isFocus ? 'soda.600' : 'black'}>
      <FontAwesome
        size={20}
        name={'lock'}
        color={isFocus ? sodaColor : 'black'}
        onPress={() => inputRef.current!.focus()}
      />
      <TextInput
        ref={inputRef}
        secureTextEntry={showPassword}
        style={[{ padding: 6, marginLeft: 6, flexGrow: 1 }, withoutOutline]}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        {...props}
      />
      {props.value ? (
        <FontAwesome
          size={20}
          name={'eye'}
          color={isFocus ? sodaColor : 'black'}
          onPress={() => setShowPassword(!showPassword)}
        />
      ) : null}
    </HStack>
  );
}
