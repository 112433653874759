import { Button, Center, Modal, Stack, Text } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { DealResponse } from '../../../api/api';
import DealDetail from '../../../components/Deal/DealDetail';
import { useAppDispatch, useAppSelector } from '../../../store/app.hooks';
import { claimDealClaimableThunk, claimDealThunk, claimLoadListThunk } from '../../../store/slices/claim.slice';
import { RootStackScreenProps } from '../../RootNavigationProps';

export default function DealDetailScreen({ navigation }: RootStackScreenProps<'DealDetail'>) {
  const deal = useAppSelector((state) => state.dealDetail.deal);
  const dispatch = useAppDispatch();

  const [claimable, setClaimable] = useState(true);
  const [isCurrentlyClaimed, setIsCurrentlyClaimed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const claimDeal = useCallback((deal: DealResponse) => {
    dispatch(claimDealThunk(deal.id)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(claimLoadListThunk());
        setIsCurrentlyClaimed(true);
      }
      return response;
    });
    setModalVisible(false);
  }, []);

  useEffect(() => {
    navigation.setOptions({ title: deal?.title });
    if (deal) {
      dispatch(claimDealClaimableThunk(deal.id)).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setClaimable(response.payload as boolean);
        }
      });
    }
  }, [deal]);

  const openSpot = (spotId: string) => {
    navigation.navigate('SpotDetail', { spotId: spotId });
  };

  return deal ? (
    <>
      <Modal isOpen={modalVisible} closeOnOverlayClick={false} onClose={() => setModalVisible(false)}>
        <Modal.Content>
          <Modal.Header>Reservieren</Modal.Header>
          <Modal.CloseButton />
          <Modal.Body>
            <Text>
              Reservierte Deals sollten innerhalb von einer Woche vor Ort eingelöst werden. Solange sind sie für Dich
              gespeichert (in Deals).
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onPress={() => {
                claimDeal(deal);
              }}>
              Reservieren
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <DealDetail deal={deal!} onSpotClick={openSpot}>
        <Stack p={4} space={4}>
          {isCurrentlyClaimed ? (
            <Center>
              <Text color={'soda.600'} fontSize={20}>
                Der Deal wurde unter deine Deals hinterlegt.
              </Text>
            </Center>
          ) : (
            <Center>
              {claimable ? (
                <Button onPress={() => setModalVisible(true)}>Reservieren</Button>
              ) : (
                <Text color={'soda.600'} fontSize={20}>
                  Dieser Deal ist bald wieder für dich verfügbar.
                </Text>
              )}
            </Center>
          )}
        </Stack>
      </DealDetail>
    </>
  ) : null;
}
