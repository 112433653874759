import { environment } from '../../constants/environment';
import { InfluencerCreateRequestDto, InfluencerResponse, InstagramMediaResponseDto } from '../api';
import { HttpClient } from '../httpClient';

const influencerEndpoint = `${environment.endpoint}/influencer`;

export function loadData(httpClient: HttpClient) {
  return httpClient.get<InfluencerResponse>(`${influencerEndpoint}/me`);
}

export function signUp(httpClient: HttpClient, createInfluencerDto: InfluencerCreateRequestDto) {
  return httpClient.post<InfluencerResponse>(`${influencerEndpoint}`, createInfluencerDto);
}

export function sendAuthCode(httpClient: HttpClient, code: string) {
  return httpClient.post<InfluencerResponse>(`${influencerEndpoint}/instagram/verification`, { code });
}

export function myInstagramMedia(httpClient: HttpClient) {
  return httpClient.get<InstagramMediaResponseDto>(`${influencerEndpoint}/instagram/media`);
}
