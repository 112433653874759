import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, NavigatorScreenParams } from '@react-navigation/native';
import { NativeStackScreenProps, createNativeStackNavigator } from '@react-navigation/native-stack';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../store/app.hooks';
import { loadInboxThunk } from '../../../store/slices/inbox.slice';
import ClaimDetailScreen from './ClaimDetailScreen';
import DealDetailScreen from './DealDetailScreen';
import { MainTabNavigator } from './MainTab/MainTabNavigator';
import MapScreen from './MapScreen';
import NotFoundScreen from './NotFoundScreen';
import SelectPostScreen from './SelectPostScreen';
import SpotDetailScreen from './SpotDetailScreen';
import TicketDetailScreen from './TicketDetailScreen';

export type MainTabParamList = {
  home: undefined;
  spots: undefined;
  claims: undefined;
  favorites: undefined;
  inbox: undefined;
  profile: undefined;
};

export type VerfiedParamList = {
  Main: NavigatorScreenParams<MainTabParamList> | undefined;
  SelectPost: undefined;
  NotFound: undefined;
  Map: undefined;
  DealDetail: { dealId: string };
  ClaimDetail: { claimId: string };
  SpotDetail: { spotId: string };
  TicketDetail: { ticketId: string };
};

export type MainTabScreenProps<Screen extends keyof MainTabParamList> = CompositeScreenProps<
  BottomTabScreenProps<MainTabParamList, Screen>,
  NativeStackScreenProps<VerfiedParamList>
>;

const inboxPollingInterval = 60 * 1000; // 10s
const Stack = createNativeStackNavigator<VerfiedParamList>();

export function VerfiedNavigator() {
  const dispatch = useAppDispatch();

  // polling for messages
  useEffect(() => {
    dispatch(loadInboxThunk());
    const polling = setInterval(() => {
      dispatch(loadInboxThunk());
    }, inboxPollingInterval);

    return () => clearInterval(polling);
  }, []);

  return (
    <Stack.Navigator id="root" screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen name="Main" component={MainTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="DealDetail" component={DealDetailScreen} />
      <Stack.Screen name="ClaimDetail" component={ClaimDetailScreen} />
      <Stack.Screen name="SpotDetail" component={SpotDetailScreen} />
      <Stack.Screen name="TicketDetail" component={TicketDetailScreen} />
      <Stack.Screen name="Map" component={MapScreen} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="SelectPost" component={SelectPostScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
