import { FontAwesome } from '@expo/vector-icons';
import { Box, Heading, HStack, Pressable, ScrollView, Stack, Text, useToken } from 'native-base';
import { ReactNode, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { DealResponse } from '../../api/api';
import { softShadow } from '../../constants/shadow';
import SlideUpAnimation from '../Animations/SlideUpAnimation';
import { View } from '../Themed';
import { DealDetailImage } from './DealDetailImage';
import DealSpotCaption from './DealSpotCaption';

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

function interfvallToText(value: string | number) {
  const valueAsString = value.toString();
  switch (valueAsString) {
    case '180':
      return 'jede 6 Monate';
    case '120':
      return 'jede 4 Monate';
    case '30':
      return 'jeden Monat';
    case '14':
      return 'jede 2 Wochen';
    case '7':
      return 'jede Woche';
    default:
      return 'jeden Monat';
  }
}

function DealDetailIntervallBadge({ intervall }: { intervall: DealResponse['claimInterval'] }) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  const intervallText = useMemo(() => interfvallToText(intervall), []);

  return (
    <HStack space={2}>
      <FontAwesome size={20} name={'credit-card'} color={sodaColor} />
      <Text ml={'-1'} color={'soda.600'}>
        {intervallText}
      </Text>
    </HStack>
  );
}

function DealDevider() {
  const [cityColor] = useToken('colors', ['city.200']);
  return (
    <View
      style={{
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: cityColor,
        marginBottom: 6,
      }}></View>
  );
}

function DealDetailTypeBadge({ type }: { type: DealResponse['type'] }) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  return (
    <HStack space={2}>
      <FontAwesome size={20} name={type === 'reel' ? 'video-camera' : 'image'} color={sodaColor} />
      <Text ml={'-1'} color={'soda.600'}>
        {type === 'reel' ? 'Reel' : 'Post'}
      </Text>
    </HStack>
  );
}

function DealDetailInner({ deal, children, onSpotClick }: DealDetailProps) {
  const openSpot = () => {
    if (onSpotClick) {
      onSpotClick(deal.spotId);
    }
  };

  return (
    <Box w={'100%'} pb={4}>
      <DealDetailImage media={deal.media} type={deal.type} />
      <Stack p={4} space={4}>
        <Stack>
          <Heading fontSize={'2xl'}>{deal.title}</Heading>
          <Text pt={2} color={'city.600'}>
            {deal.description}
          </Text>
        </Stack>
        <HStack pt={4} space={4} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <Stack>
            <Text color={'city.600'}>Preis</Text>
            <Text fontSize={24} color={'prawn.600'}>
              {formatter.format(deal.reducedPrice)}
            </Text>
          </Stack>
          <Stack>
            <Text color={'city.600'}>Original Preis</Text>
            <Text color={'city.600'}>{formatter.format(deal.regularPrice)}</Text>
          </Stack>
        </HStack>
        <Pressable p={2} bgColor={'city.50'} borderRadius={'2xl'} onPress={() => openSpot()}>
          <DealSpotCaption spotCaption={deal.spotCaption}></DealSpotCaption>
        </Pressable>
      </Stack>
      <DealDevider />
      <Stack p={4} space={4}>
        <Stack>
          <Text fontSize={'md'} fontWeight={'bold'}>
            Bedingungen
          </Text>
          <Text color={'city.600'}>{deal.condition}</Text>
        </Stack>
        <HStack space={4} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <Stack>
            <Text color={'city.600'}>Typ</Text>
            <Box pt={2}>
              <DealDetailTypeBadge type={deal.type} />
            </Box>
          </Stack>
          <Stack>
            <Text color={'city.600'}>Intervall</Text>
            <Box pt={2}>
              <DealDetailIntervallBadge intervall={deal.claimInterval} />
            </Box>
          </Stack>
        </HStack>
        <Stack pt={2}>
          <Text color={'city.600'}>Caption</Text>
          <Text color={'city.600'} pt={2}>
            Schreibe einpaar Zeilen, was Dir bei Deinem Besuch gefallen hat. Nutze die vorgegebenen Markierungen und
            Hashtags.
          </Text>
        </Stack>
        <Stack pt={2}>
          <Text color={'city.600'}>Markierungen</Text>
          <HStack mt={2} p={4} backgroundColor={'city.100'} flexWrap={'wrap'} space={2} borderRadius={8}>
            {deal.mentions.map((mention) => (
              <Text key={mention}>@{mention}</Text>
            ))}
          </HStack>
        </Stack>
        <Stack pt={2}>
          <Text color={'city.600'}>Hashtags zu benutzen</Text>
          <HStack mt={2} p={4} backgroundColor={'city.100'} flexWrap={'wrap'} space={2} borderRadius={8}>
            {deal.tags.map((tag) => (
              <Text key={tag}>#{tag}</Text>
            ))}
          </HStack>
        </Stack>
      </Stack>
      <DealDevider />
      {children}
    </Box>
  );
}

export interface DealDetailProps {
  deal: DealResponse;
  children?: ReactNode;
  onSpotClick?: (id: string) => void;
}

export default function DealDetail({ deal, children, onSpotClick }: DealDetailProps) {
  const windowDimensions = useWindowDimensions();

  return (
    <ScrollView pb={8}>
      <SlideUpAnimation>
        {windowDimensions.width > 460 ? (
          <Stack alignItems={'center'} py={4}>
            <Box maxWidth={460} overflow={'hidden'} rounded={'2xl'} style={softShadow}>
              <DealDetailInner deal={deal} onSpotClick={onSpotClick}>
                {children}
              </DealDetailInner>
            </Box>
          </Stack>
        ) : (
          <DealDetailInner deal={deal} onSpotClick={onSpotClick}>
            {children}
          </DealDetailInner>
        )}
      </SlideUpAnimation>
    </ScrollView>
  );
}
