/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from './RootNavigationProps';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Main: {
        screens: {
          home: {
            path: 'home',
          },
          spots: {
            path: 'spots',
            screens: {
              Spots: '',
            },
          },
          claims: {
            path: 'deals',
          },
          profile: {
            path: 'profile',
            screens: {
              ProfileScreen: 'profile',
            },
          },
          favorites: {
            path: 'favorites',
            screens: {
              ProfileScreen: 'favorite',
            },
          },
          inbox: {
            path: 'inbox',
            screens: {
              ProfileScreen: 'message',
            },
          },
        },
      },
      Map: {
        path: 'map',
      },
      SpotDetail: {
        path: 'spot',
      },
      InfluencerSignUp: {
        path: 'influencer-sign-up',
      },
      InfluencerVerification: {
        path: 'verification',
      },
      InstagramLogin: {
        path: 'connect-with-instagram',
      },
      InstagramVerification: {
        path: 'influencer/instagram-verification',
      },
      DealDetail: {
        path: 'deal',
      },
      ClaimDetail: {
        path: 'claim',
      },
      TicketDetail: {
        path: 'ticket',
      },
      Login: {
        path: 'login',
      },
      SignUp: {
        path: 'sign-up',
      },
      ResetPassword: {
        path: 'reset-password',
      },
      NewPassword: {
        path: 'auth/new-password',
      },
      Activate: {
        path: 'auth/activate/:id',
      },
      SelectPost: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
