import { Center, Heading, HStack, KeyboardAvoidingView, Pressable, Stack, Text, useToast } from 'native-base';
import { useState } from 'react';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';
import { InputWithIcon } from '../../components/StyledInput';

import LinkFooter from '../../components/Utils/LinkFooter';
import { useAppDispatch } from '../../store/app.hooks';
import { resetPasswordThunk } from '../../store/slices/user.slice';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function ResetPasswordScreen({ navigation }: RootStackScreenProps<'ResetPassword'>) {
  const [email, setEmail] = useState('');

  const dispatch = useAppDispatch();
  const toast = useToast();

  function resetPassword() {
    dispatch(resetPasswordThunk(email)).then((response) => {
      if (response.meta.requestStatus === 'rejected') {
        toast.show({
          id: response.meta.requestId,
          title: (response as any).error.message,
          placement: 'bottom-right',
          mx: 10,
        });
      }
    });
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <ShadowBox buttonText="Passwort zurücksetzen" onButtonPress={() => resetPassword()}>
            <Heading textAlign={'left'} size={'sm'}>
              Passwort vergessen?
            </Heading>
            <Text>Bitte gib Deine E-Mail Adresse ein.</Text>
            <InputWithIcon
              placeholder={'Email'}
              value={email}
              onChangeText={(text) => setEmail(text)}
              iconName={'user'}></InputWithIcon>
          </ShadowBox>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => navigation.navigate('Login')}>
                <Text textDecorationLine={'underline'}>Zurück zu Login</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
