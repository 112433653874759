import { Box, Image, ScrollView, Stack, Text } from 'native-base';
import { useWindowDimensions } from 'react-native';
import { TicketResponse } from '../../api/api';
import { timestampToDate } from '../utils';
import logo from './../../assets/images/spotliked-logo-icon-white.png';

function TicketDetailInner({ ticket }: TicketDetailProps) {
  return (
    <Stack p={4} backgroundColor={'city.100'} rounded={'xl'} space={2}>
      <Image source={logo} alt={'spotliked logo'} w={'100px'} h={'100px'} backgroundColor={'soda.600'}></Image>
      <Text fontWeight={'bold'}>{ticket.subject}</Text>
      {ticket.messages.map((message) => (
        <Stack key={message.date} flex={1}>
          <Box flex={1} alignItems={'flex-end'}>
            <Text>{timestampToDate(message.date)}</Text>
          </Box>
          <Text flex={1}>{message.text}</Text>
        </Stack>
      ))}
    </Stack>
  );
}

export interface TicketDetailProps {
  ticket: TicketResponse;
}

export default function TicketDetail({ ticket }: TicketDetailProps) {
  const windowDimensions = useWindowDimensions();

  return (
    <>
      {windowDimensions.width > 900 ? (
        <Stack alignItems={'center'} py={4}>
          <Box w={900}>
            <TicketDetailInner ticket={ticket}></TicketDetailInner>
          </Box>
        </Stack>
      ) : (
        <ScrollView w={'100%'} h={'100%'} p={4}>
          <TicketDetailInner ticket={ticket}></TicketDetailInner>
        </ScrollView>
      )}
    </>
  );
}
