import { Center, Heading, HStack, Image, Stack, Text } from 'native-base';
import { useMemo } from 'react';
import { SpotResponse } from '../../api/api';
import { softShadow } from '../../constants/shadow';
import { getImageUrl, ImageSize } from '../utils';

export interface SpotSummeryProps {
  spot: SpotResponse;
}

export default function SpotSummery({ spot }: SpotSummeryProps) {
  const image = useMemo(() => getImageUrl(spot.media.logo, ImageSize.SM), [spot.media]);
  const source = image ? { uri: image } : undefined;
  return (
    <HStack
      justifyContent={'flex-start'}
      alignItems={'center'}
      backgroundColor={'white'}
      flex={1}
      flexGrow={1}
      maxW={'100%'}
      rounded={'xl'}
      py={'1'}
      px={'2'}
      style={softShadow}>
      <Center>
        <Image source={source} w={'50px'} h={'50px'} rounded={'xl'} resizeMode={'cover'} alt={'Image of Spot Logo'} />
      </Center>

      <Stack p={'2'} flex={1}>
        <Heading size={'md'} isTruncated noOfLines={1}>
          {spot.title}
        </Heading>
        <Text isTruncated noOfLines={1} fontWeight={'400'} color={'city.500'}>
          {spot.address.street}, {spot.address.areacode} {spot.address.place}
        </Text>
      </Stack>

      {spot.deals?.length ? (
        <Stack>
          <Stack
            px={2}
            bgColor={'soda.500'}
            rounded={'full'}
            w={6}
            h={6}
            alignItems={'center'}
            justifyContent={'center'}>
            <Text color={'white'} fontWeight={'bold'}>
              {spot.deals!.length}
            </Text>
          </Stack>
        </Stack>
      ) : null}
    </HStack>
  );
}
