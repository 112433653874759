import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { MainTabScreenProps } from '../VerifiedNavigation';
import ClaimScreen from './ClaimScreen';

export type ClaimStackParamList = {
  Claims: undefined;
};

export type ClaimNavigationScreenProps<Screen extends keyof ClaimStackParamList> = NativeStackScreenProps<
  ClaimStackParamList,
  Screen
>;

const Stack = createNativeStackNavigator<ClaimStackParamList>();

export default function ClaimNavigator({ navigation }: MainTabScreenProps<'claims'>) {
  return (
    <Stack.Navigator screenOptions={{ contentStyle: { backgroundColor: '#fff' } }}>
      <Stack.Screen
        name="Claims"
        component={ClaimScreen}
        options={{
          title: 'Deals',
        }}
      />
    </Stack.Navigator>
  );
}
