import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { FavoriteAddRequestDto, FavoriteRemoveRequestDto, FavoriteResponse, SpotResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { PaginatedResponse } from '../../api/paginated.interface';
import { favoriteAdd, favoriteLoadList, favoriteRemove } from '../../api/services/favorite.service';
import { RootState } from '../app.store';

export const favoriteLoadListThunk = createAsyncThunk('favorite/list', () => {
  return favoriteLoadList(httpClient);
});

export const favoriteAddThunk = createAsyncThunk('favorite/add', (spot: SpotResponse) => {
  const favoriteAddDto: FavoriteAddRequestDto = { id: spot.id, favoriteType: 'spot' };
  return favoriteAdd(httpClient, favoriteAddDto);
});

export const favoriteRemoveThunk = createAsyncThunk('favorite/remove', (spot: SpotResponse) => {
  const favoriteRemoveDto: FavoriteRemoveRequestDto = { id: spot.id, favoriteType: 'spot' };
  return favoriteRemove(httpClient, favoriteRemoveDto);
});

const favoriteAdapter = createEntityAdapter<FavoriteResponse>({
  selectId: (favorite) => favorite.id,
});

export interface FavoriteState {
  list: EntityState<FavoriteResponse>;
  api_response: PaginatedResponse<FavoriteResponse> | null;
}

const initialState: FavoriteState = {
  list: favoriteAdapter.getInitialState(),
  api_response: null,
};

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addFavorite(state, action: PayloadAction<FavoriteResponse>) {
      favoriteAdapter.addOne(state.list, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      favoriteLoadListThunk.fulfilled,
      (state, action: PayloadAction<PaginatedResponse<FavoriteResponse>>) => {
        favoriteAdapter.setAll(state.list, action.payload.data);
        state.api_response = action.payload;
      },
    );
  },
});

export const {} = favoriteSlice.actions;
export const { selectAll: favoriteListAll } = favoriteAdapter.getSelectors((state: RootState) => state.favorite.list);

export default favoriteSlice.reducer;
