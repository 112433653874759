import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { SpotResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { PaginatedResponse } from '../../api/paginated.interface';
import { searchSpot } from '../../api/services/search.service';
import { RootState } from '../app.store';

export const searchSpotThunk = createAsyncThunk(
  'search/spots',
  ({ search, pagination }: { search?: string | null; pagination?: number | null }) => {
    return searchSpot(httpClient, { search, pagination });
  },
);

const searchAdapter = createEntityAdapter<SpotResponse>({
  selectId: (spot) => spot.id,
});

export interface SearchState {
  searchTag: string | null;
  isLoading: boolean;
  list: EntityState<SpotResponse>;
  count: number;
  currentPage: number;
  api_response: PaginatedResponse<SpotResponse> | null;
}

const initialState: SearchState = {
  searchTag: null,
  isLoading: false,
  list: searchAdapter.getInitialState(),
  count: 0,
  currentPage: 1,
  api_response: null,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTag: (state, action: PayloadAction<string | null>) => {
      state.searchTag = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchSpotThunk.fulfilled, (state, action: PayloadAction<PaginatedResponse<SpotResponse>>) => {
      state.isLoading = false;
      searchAdapter.setAll(state.list, action.payload.data);
      state.count = action.payload.count;
      state.api_response = action.payload;
    });
    builder.addCase(searchSpotThunk.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setSearchTag, setCurrentPage } = searchSlice.actions;
export const { selectAll: spotListAll } = searchAdapter.getSelectors((state: RootState) => state.search.list);

export default searchSlice.reducer;
