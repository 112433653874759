import { Center, Heading, HStack, KeyboardAvoidingView, Pressable, Stack, Text, useToast } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';
import { InputWithIcon, PasswordInput } from '../../components/StyledInput';
import { useUser } from '../../hooks/useUser';

import LinkFooter from '../../components/Utils/LinkFooter';
import { useAppDispatch } from '../../store/app.hooks';
import { loginThunk } from '../../store/slices/user.slice';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function LoginScreen({ navigation }: RootStackScreenProps<'Login'>) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  const toast = useToast();

  const shakeValue = useRef(new Animated.Value(0)).current;
  const shakeAnimation = useRef(
    Animated.sequence([
      Animated.timing(shakeValue, {
        toValue: -4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: -4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 4,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(shakeValue, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }),
    ]),
  );

  const user = useUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.data) {
      navigation.navigate('Main');
    }
  }, [user]);

  function login() {
    if (!email || !password) {
      return;
    }
    setShowLoading(true);
    dispatch(loginThunk({ email: email.trim().toLocaleLowerCase(), password }))
      .then((response) => {
        if (response.meta.requestStatus === 'rejected') {
          shakeAnimation.current.reset();
          shakeAnimation.current.start();
          toast.show({
            id: response.meta.requestId,
            title: (response as any).error.message,
            placement: 'bottom-right',
            mx: 10,
          });
        }
      })
      .finally(() => setShowLoading(false));
  }

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <Animated.View style={{ transform: [{ translateX: shakeValue }] }}>
            <ShadowBox buttonText="Login" onButtonPress={login} showLoading={showLoading}>
              <Heading textAlign={'left'} size={'sm'} bold={true}>
                Melde dich mit deinem Account an
              </Heading>
              <InputWithIcon
                placeholder={'Email'}
                value={email}
                onChangeText={(text) => setEmail(text)}
                onSubmitEditing={() => login()}
                iconName={'user'}></InputWithIcon>
              <PasswordInput
                placeholder={'Passwort'}
                value={password}
                onChangeText={(text) => setPassword(text)}
                onSubmitEditing={() => login()}></PasswordInput>
            </ShadowBox>
          </Animated.View>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => navigation.navigate('SignUp')}>
                <Text textDecorationLine={'underline'}>Registrieren</Text>
              </Pressable>
              <Pressable onPress={() => navigation.navigate('ResetPassword')}>
                <Text textDecorationLine={'underline'}>Passwort vergessen</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
