import Svg, { Path } from 'react-native-svg';

export function TabBarFavoritesDefault({ size = 24, color = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.243 3.75676C18.9 1.41376 15.101 1.41376 12.758 3.75676C12.469 4.04576 12.218 4.35676 12.001 4.68376C11.784 4.35676 11.532 4.04476 11.244 3.75676C8.901 1.41376 5.102 1.41376 2.759 3.75676C0.416001 6.09976 0.416001 9.89876 2.759 12.2418L12 21.4848L21.243 12.2418C23.586 9.89876 23.586 6.09976 21.243 3.75676Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </Svg>
  );
}

export function TabBarFavoritesActive({ size = 24, color = '#00AAF2', strokeColor = '#000B42' }) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.243 3.75676C18.9 1.41376 15.101 1.41376 12.758 3.75676C12.469 4.04576 12.218 4.35676 12.001 4.68376C11.784 4.35676 11.532 4.04476 11.244 3.75676C8.901 1.41376 5.102 1.41376 2.759 3.75676C0.416001 6.09976 0.416001 9.89876 2.759 12.2418L12 21.4848L21.243 12.2418C23.586 9.89876 23.586 6.09976 21.243 3.75676Z"
        fill={color}
        stroke={strokeColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </Svg>
  );
}
