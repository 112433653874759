import { Center, Heading, HStack, KeyboardAvoidingView, Pressable, Spinner, Stack, Text } from 'native-base';
import { useEffect } from 'react';
import { PostResponseDto } from '../../api/api';
import { SpotlikedLogo } from '../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../components/ShadowBox';

import LinkFooter from '../../components/Utils/LinkFooter';
import { useAppDispatch } from '../../store/app.hooks';
import { activateThunk } from '../../store/slices/user.slice';
import { RootStackScreenProps } from '../RootNavigationProps';

export default function ActivateScreen({ navigation, route }: RootStackScreenProps<'Activate'>) {
  const dispatch = useAppDispatch();

  const activateId = route.params.id;

  function activate(id: string) {
    dispatch(activateThunk(id)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled' && (response.payload as PostResponseDto).type === 'info') {
        navigation.navigate('Login');
      }
    });
  }

  useEffect(() => {
    if (activateId) {
      activate(activateId);
    }
  }, [activateId]);

  return (
    <Stack h={'100%'} maxH={'900px'} justifyContent={'space-between'} pt={8} alignItems={'center'}>
      <SpotlikedLogo />
      <KeyboardAvoidingView behavior="position">
        <Stack space={2}>
          <ShadowBox>
            <Heading textAlign={'left'} size={'sm'}>
              Account wird aktiviert
            </Heading>
            <Text>Danke für die Registrierung. Dein Account wird in Kürze aktiviert.</Text>
            <Center>
              <Spinner size={40} color={'soda.500'}></Spinner>
            </Center>
          </ShadowBox>

          <Center>
            <HStack p={3} space={3}>
              <Pressable onPress={() => navigation.navigate('Login')}>
                <Text textDecorationLine={'underline'}>Zurück zu Login</Text>
              </Pressable>
            </HStack>
          </Center>
        </Stack>
      </KeyboardAvoidingView>
      <LinkFooter></LinkFooter>
    </Stack>
  );
}
