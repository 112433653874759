import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketResponse } from '../../api/api';
import { httpClient } from '../../api/httpClient';
import { PaginatedResponse } from '../../api/paginated.interface';
import { loadTickets } from '../../api/services/ticket.service';

export const loadTicketThunk = createAsyncThunk('ticket/me', () => {
  return loadTickets(httpClient);
});

export interface TicketState {
  selectedTicket: TicketResponse | null;
  api_response: PaginatedResponse<TicketResponse> | null;
}

const initialState: TicketState = {
  selectedTicket: null,
  api_response: null,
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setTicket: (state, action: PayloadAction<TicketResponse | null>) => {
      state.selectedTicket = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTicketThunk.fulfilled, (state, action: PayloadAction<PaginatedResponse<TicketResponse>>) => {
      state.api_response = action.payload;
    });
  },
});

export const { setTicket } = ticketSlice.actions;

export default ticketSlice.reducer;
