import { environment } from '../../constants/environment';
import { ClaimConnectContentRequestDto, ClaimResponse } from '../api';
import { HttpClient } from '../httpClient';
import { PaginatedResponse } from '../paginated.interface';

const claimEndpoint = `${environment.endpoint}/claim`;

export function claimLoadList(httpClient: HttpClient) {
  return httpClient.get<PaginatedResponse<ClaimResponse>>(`${claimEndpoint}/me`);
}

export function claimDeal(httpClient: HttpClient, dealId: string) {
  return httpClient.post<ClaimResponse>(`${claimEndpoint}`, { id: dealId });
}

export function claimDealClaimable(httpClient: HttpClient, dealId: string) {
  return httpClient.get<boolean>(`${claimEndpoint}/deal/${dealId}/claimable`);
}

export function claimActivate(httpClient: HttpClient, claimId: string) {
  return httpClient.get<ClaimResponse>(`${claimEndpoint}/activate/${claimId}`);
}

export function claimConnectMedia(httpClient: HttpClient, claimId: string, mediaId: string) {
  const claimConnectContentRequestDto: ClaimConnectContentRequestDto = {
    claimId,
    mediaId,
  };
  return httpClient.post<ClaimResponse>(`${claimEndpoint}/connect`, claimConnectContentRequestDto);
}
