import Svg, { Path } from 'react-native-svg';

export function SpotlikedLogo({ width = 255, height = 120, color = '#00AAF2' }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 588 120" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4176 46.6047C86.4578 56.9302 86.4578 73.3953 76.4176 83.4419L47.6913 112.465L40.9977 119.163C40.44 119.721 39.6033 120 39.0455 120C37.651 120 36.2565 118.884 36.2565 117.209V85.1163C40.7189 83.7209 44.6234 81.2093 47.6913 78.1395V96L67.7718 75.6279C68.0507 75.6279 68.0507 75.3488 68.3296 75.3488C73.9075 69.7674 73.9075 60.5581 68.3296 54.6977C62.7517 49.1163 53.5481 49.1163 47.9702 54.6977C47.6913 54.9767 47.1335 55.5349 46.8546 56.093L46.5757 56.3721C45.4601 57.7674 43.7867 58.6046 42.1133 58.6046C39.0455 58.6046 36.2565 56.093 36.2565 52.7442C36.2565 51.6279 36.5354 50.5116 37.0932 49.3953L37.3721 49.1163C38.2088 48 38.7666 47.1628 39.8822 46.3256C49.9224 36.2791 66.3773 36.2791 76.4176 46.6047ZM44.9023 0C46.2968 0 47.6913 1.11628 47.6913 2.7907V34.8837C43.2289 36.2791 39.3244 38.7907 36.2565 41.8605V24L16.176 44.3721C15.8971 44.3721 15.8971 44.6512 15.6182 44.6512C10.0403 50.2326 10.0403 59.4419 15.6182 65.3023C21.1961 70.8837 30.3997 70.8837 35.9776 65.3023C36.2565 65.0233 36.8143 64.4651 37.0932 63.907L37.3721 63.6279C38.4877 62.2326 40.1611 61.3953 41.8344 61.3953C44.9023 61.3953 47.6913 63.907 47.6913 67.2558C47.6913 68.3721 47.4124 69.4884 46.8546 70.6046L46.5757 70.8837C45.739 72 45.1812 72.8372 44.0656 73.6744C34.0253 84 17.5705 84 7.5302 73.6744C-2.51007 63.3488 -2.51007 46.8837 7.5302 36.8372L36.2565 7.81395L42.95 1.11628C43.5078 0.27907 44.0656 0 44.9023 0Z"
        fill={color}
      />
      <Path
        d="M159.204 77.1426C159.204 80.4518 158.377 83.5159 156.723 86.3349C155.07 89.0926 152.62 91.3294 149.374 93.0453C146.128 94.6999 142.178 95.5272 137.524 95.5272C132.93 95.5272 128.827 94.7612 125.213 93.2291C121.6 91.6358 118.691 89.3683 116.486 86.4268C114.343 83.4853 113.118 80.0535 112.812 76.1314H126.591C126.898 78.7666 127.939 80.9727 129.715 82.7499C131.552 84.4658 133.971 85.3237 136.972 85.3237C139.667 85.3237 141.719 84.619 143.127 83.2095C144.597 81.7387 145.332 79.8696 145.332 77.6022C145.332 75.5799 144.75 73.9253 143.587 72.6384C142.423 71.2902 140.953 70.2177 139.177 69.4211C137.401 68.6244 134.951 67.7052 131.828 66.6634C127.786 65.3152 124.479 63.9976 121.906 62.7107C119.334 61.3625 117.129 59.4321 115.292 56.9195C113.516 54.407 112.628 51.1284 112.628 47.0838C112.628 41.5071 114.618 37.1255 118.599 33.9388C122.641 30.6909 127.969 29.0669 134.584 29.0669C141.443 29.0669 146.894 30.7215 150.936 34.0307C155.039 37.34 157.367 41.7523 157.918 47.2676H143.954C143.587 44.9389 142.607 43.0392 141.014 41.5684C139.422 40.0364 137.248 39.2703 134.492 39.2703C132.103 39.2703 130.174 39.9138 128.704 41.2007C127.235 42.4264 126.5 44.2342 126.5 46.6242C126.5 48.5239 127.051 50.1172 128.153 51.4042C129.317 52.6298 130.756 53.641 132.471 54.4376C134.247 55.2343 136.635 56.1229 139.636 57.1034C143.801 58.5742 147.169 59.953 149.742 61.2399C152.375 62.5268 154.611 64.4572 156.448 67.0311C158.285 69.6049 159.204 72.9754 159.204 77.1426Z"
        fill={color}
      />
      <Path
        d="M182.617 70.7999V94.8837H169.664V29.9861H193.274C200.929 29.9861 206.686 31.8246 210.545 35.5015C214.464 39.1784 216.424 44.1729 216.424 50.4849C216.424 56.6744 214.403 61.6076 210.361 65.2845C206.38 68.9614 200.684 70.7999 193.274 70.7999H182.617ZM191.896 60.4126C199.49 60.4126 203.287 57.1034 203.287 50.4849C203.287 47.237 202.399 44.7551 200.623 43.0392C198.847 41.3233 195.938 40.4653 191.896 40.4653H182.617V60.4126H191.896Z"
        fill={color}
      />
      <Path
        d="M256.164 29.0669C262.227 29.0669 267.708 30.4764 272.608 33.2953C277.569 36.1143 281.458 40.067 284.275 45.1534C287.153 50.1785 288.592 55.8777 288.592 62.2511C288.592 68.6244 287.153 74.3543 284.275 79.4407C281.458 84.5271 277.569 88.4798 272.608 91.2987C267.708 94.1177 262.227 95.5272 256.164 95.5272C250.101 95.5272 244.589 94.1177 239.628 91.2987C234.728 88.4798 230.84 84.5271 227.961 79.4407C225.144 74.3543 223.735 68.6244 223.735 62.2511C223.735 55.8777 225.144 50.1785 227.961 45.1534C230.84 40.067 234.728 36.1143 239.628 33.2953C244.589 30.4764 250.101 29.0669 256.164 29.0669ZM256.164 41.0169C252.367 41.0169 249.029 41.8748 246.15 43.5907C243.272 45.3066 241.006 47.7885 239.352 51.0365C237.76 54.2231 236.964 57.9613 236.964 62.2511C236.964 66.5408 237.76 70.3096 239.352 73.5576C241.006 76.7443 243.272 79.1955 246.15 80.9114C249.029 82.6273 252.367 83.4853 256.164 83.4853C259.961 83.4853 263.299 82.6273 266.177 80.9114C269.056 79.1955 271.291 76.7443 272.883 73.5576C274.537 70.3096 275.364 66.5408 275.364 62.2511C275.364 57.9613 274.537 54.2231 272.883 51.0365C271.291 47.7885 269.056 45.3066 266.177 43.5907C263.299 41.8748 259.961 41.0169 256.164 41.0169Z"
        fill={color}
      />
      <Path d="M343.348 29.9861V40.2815H325.434V94.8837H312.481V40.2815H294.659V29.9861H343.348Z" fill={color} />
      <Path d="M364.35 84.956H386.122V94.8837H351.397V29.9861H364.35V84.956Z" fill={color} />
      <Path d="M407.703 29.9861V94.8837H394.75V29.9861H407.703Z" fill={color} />
      <Path
        d="M445.511 61.8834L472.979 94.8837H457.269L433.293 65.1926V94.8837H420.339V29.9861H433.293V59.1257L457.361 29.9861H472.979L445.511 61.8834Z"
        fill={color}
      />
      <Path
        d="M494.05 40.2815V57.1034H517.016V67.0311H494.05V84.4964H519.772V94.8837H481.096V29.9861H519.772V40.2815H494.05Z"
        fill={color}
      />
      <Path
        d="M587.329 62.343C587.329 68.7776 585.982 74.4462 583.287 79.3487C580.592 84.2513 576.673 88.0814 571.528 90.8391C566.445 93.5355 560.382 94.8837 553.339 94.8837H530.281V29.9861H553.339C560.382 29.9861 566.445 31.3343 571.528 34.0307C576.673 36.6659 580.592 40.4347 583.287 45.3373C585.982 50.1785 587.329 55.8471 587.329 62.343ZM552.236 83.3014C559.218 83.3014 564.608 81.463 568.405 77.7861C572.263 74.1091 574.192 68.9614 574.192 62.343C574.192 55.6633 572.263 50.4849 568.405 46.808C564.608 43.1311 559.218 41.2926 552.236 41.2926H543.234V83.3014H552.236Z"
        fill={color}
      />
    </Svg>
  );
}

export function SpotlikedIcon({ width = 250, height = 250, color = '#00AAF2' }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 250 250" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.001 102.451C201.333 119.444 201.333 146.994 184.001 163.987L183.993 163.996L134.863 212.508L123.567 223.582C122.641 224.49 121.384 225 120.074 225C117.471 225 115.339 223.028 115.15 220.526L115.136 220.165V167.05C122.58 164.523 129.289 160.452 134.864 155.227L134.863 184.95L169.342 151.013L169.324 150.998C169.569 150.775 169.813 150.547 170.05 150.312C179.681 140.872 179.681 125.566 170.05 116.126C160.423 106.686 144.81 106.686 135.18 116.126C134.48 116.812 133.831 117.53 133.232 118.274L132.89 118.715C131.092 121.063 128.227 122.583 125 122.583C119.552 122.583 115.136 118.254 115.136 112.913C115.136 110.935 115.743 109.095 116.783 107.562L117.109 107.112C118.354 105.481 119.728 103.926 121.232 102.451C138.565 85.4585 166.666 85.4585 184.001 102.451ZM129.925 25C132.528 25 134.661 26.9723 134.849 29.474L134.863 29.8347V82.9505C127.419 85.4769 120.71 89.5479 115.135 94.7728L115.137 65.0497L80.6564 98.987L80.6759 99.0022C80.4296 99.2248 80.1869 99.4535 79.9479 99.6878C70.3187 109.128 70.3187 124.434 79.9479 133.874C89.5772 143.315 105.189 143.315 114.819 133.874C115.519 133.188 116.168 132.47 116.767 131.726L117.109 131.285C118.907 128.937 121.772 127.417 125 127.417C130.447 127.417 134.863 131.746 134.863 137.087C134.863 139.065 134.256 140.905 133.216 142.438L132.89 142.889C131.646 144.519 130.272 146.074 128.767 147.549C111.434 164.541 83.3322 164.541 65.9995 147.549C48.6668 130.556 48.6668 103.006 65.9995 86.013L66.005 86.0058L115.137 37.4919L126.432 26.4183C127.358 25.5103 128.615 25 129.925 25Z"
        fill={color}
      />
    </Svg>
  );
}
