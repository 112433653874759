import { HStack, Text } from 'native-base';
import { Linking, Pressable } from 'react-native';

export default function LinkFooter() {
  return (
    <HStack space={2} p={6} w={'100%'}>
      <Pressable onPress={() => Linking.openURL('mailto:service@spotlike.de')}>
        <Text textDecorationLine={'underline'}>Kontakt</Text>
      </Pressable>
      <Pressable onPress={() => Linking.openURL('https://spotliked.de/impressum/')}>
        <Text textDecorationLine={'underline'}>Impressum</Text>
      </Pressable>
      <Pressable onPress={() => Linking.openURL('https://spotliked.de/datenschutz/')}>
        <Text textDecorationLine={'underline'}>Datenschutz</Text>
      </Pressable>
    </HStack>
  );
}
